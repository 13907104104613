import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { MetaService } from 'src/app/services/meta.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {

  constructor(private titleService:Title, private as: AnalyticsService, public _metaTags: MetaService) { 
    
  }

  ngOnInit(): void {
    
    this.titleService.setTitle("Newbeemountain");
    this._metaTags.setBasicMetaTags({
      title: 'NewBeeMountain',      
      description: 'Ihr Partner für IT Lösungen',
      keywords: ['Prozessoptimierung', 'IT', 'Web', '3D']
    })
  }

  acceptCookies(){
    this.as.startTracking();
  }
}