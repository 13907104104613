<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>
                <h1>{{'web_dev.title' | translate}}</h1>
                <p>{{'web_dev.description' | translate}}</p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>
                <ng-lottie [options]="web_dev"></ng-lottie>
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <div class="section-1">
        <app-wrapper>
            <h1>{{'web_dev.headline' | translate}}</h1>
            <div class="grid">
                <div class="div1 item">
                    <div class="image-wrapper">
                        <ng-lottie [options]="design">
                        </ng-lottie>
                    </div>
                    <h2>{{'web_dev.section_1.title' | translate}}</h2>
                    <p>{{'web_dev.section_1.text' | translate}}</p>
                </div>
                <div class="div2 item">

                    <div class="image-wrapper">
                        <ng-lottie [options]="screen_size">
                        </ng-lottie>
                    </div>
                    <h2>{{'web_dev.section_2.title' | translate}}</h2>
                    <p>{{'web_dev.section_2.text' | translate}}</p>
                </div>
                <div class="div3 item">
                    <div class="image-wrapper">
                        <ng-lottie [options]="web_frameworks">
                        </ng-lottie>
                    </div>
                    <h2>{{'web_dev.section_3.title' | translate}}</h2>
                    <p>{{'web_dev.section_3.text' | translate}}</p>
                </div>
                <div class="div4 item">
                    <div class="image-wrapper">
                        <ng-lottie [options]="cms">
                        </ng-lottie>
                    </div>
                    <h2>{{'web_dev.section_4.title' | translate}}</h2>
                    <p>{{'web_dev.section_4.text' | translate}}</p>
                </div>
                <div class="div5 item">

                    <div class="wide-wrapper">
                        <ng-lottie [options]="headless_cms">
                        </ng-lottie>
                    </div>
                    <p>{{'web_dev.section_5' | translate}}</p>

                </div>
            </div>
        </app-wrapper>
    </div>
    <!-- <div class="section">
        <app-wrapper>
            <h1>{{'web_dev.section_6.title' | translate}}</h1>
            <div class="ws_grid">
                <div class="webshoptext" innerHTML="{{'web_dev.section_6.text' | translate}}">
                    
                </div>
                <div class="item demoshop">
                    <div>
                        <ng-lottie [options]="web_shop"></ng-lottie>
                    </div>
                    <div class="top">
                        <a class="btn" target="_blank" href="https://confidatotest.myshopify.com/">{{'web_dev.section_6.btn' | translate}}</a>
                    </div>
                </div>
            </div>
        </app-wrapper>
    </div> -->
    <div class="section section-2">
        <app-wrapper>
            <h1>{{'web_dev.section_7.title' | translate}}</h1>
            <div class="techstack-wrapper">
                <app-tech-grid [techstack]="techstack"></app-tech-grid>
            </div>
            <app-contact-person name="Patrick Hörtreiter"></app-contact-person>
        </app-wrapper>
    </div>
    
</div>