import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { Mesh, Object3D } from 'three';

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.scss']
})
export class VisualComponent implements OnInit {

  @ViewChild('canvas', { static: true }) canvasRef: ElementRef;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }
  constructor() {

  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    const scene = new THREE.Scene()

    /**
     * Loaders
     */
    // Texture loader
    const textureLoader = new THREE.TextureLoader()

    // Draco loader
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('draco/')

    // GLTF loader
    const gltfLoader = new GLTFLoader()
    gltfLoader.setDRACOLoader(dracoLoader)

    /**
     * Object
     */

    /**
     * Textures
     */

    const bakedTexture = textureLoader.load('assets/visual/baked-nbm.jpg')
    bakedTexture.flipY = false
    bakedTexture.encoding = THREE.sRGBEncoding

    /**
     * Materials
     */

    //Baked material

    const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })


    //Pole light material


    const lightMaterial = new THREE.MeshBasicMaterial({ color: 0xfffff5 })
    /**
     * Model
     */
    const group = new THREE.Group()
    gltfLoader.load(
      'assets/visual/nbmtest.glb',
      (gltf) => {
        gltf.scene.traverse((child: any) => {
          child.material = bakedMaterial
        })
        group.add(gltf.scene)

        scene.add(group)
        const light: any = gltf.scene.children.find((child) => child.name === 'lamp')
        light.material = lightMaterial
        const cloud: any = gltf.scene.children.find((child) => child.name === 'cloud')
        cloud.material = lightMaterial
        const display: any = gltf.scene.children.find((child) => child.name === 'display')
        display.material = lightMaterial
        // const cable_computer:any = gltf.scene.children.find((child) => child.name === 'cable_computer')
        // cable_computer.material = lightMaterial
        // const cable_server:any = gltf.scene.children.find((child) => child.name === 'cable_server')
        // cable_server.material = lightMaterial
        // const cable_phone:any = gltf.scene.children.find((child) => child.name === 'cable_phone')
        // cable_phone.material = lightMaterial
        // const cable_laptop:any = gltf.scene.children.find((child) => child.name === 'cable_laptop')
        // cable_laptop.material = lightMaterial

        const green_1: any = gltf.scene.children.find((child) => child.name === 'green_1')
        green_1.material = lightMaterial
        const green_2: any = gltf.scene.children.find((child) => child.name === 'green_2')
        green_2.material = lightMaterial
        const green_3: any = gltf.scene.children.find((child) => child.name === 'green_3')
        green_3.material = lightMaterial

        gltf.scene.position.y = -2
      }
    )



    /**
     * Sizes
     */
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    })

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
    camera.position.x = 4.8
    camera.position.y = 0.7
    camera.position.z = -5
    scene.add(camera)

    //Controls
    const controls = new OrbitControls(camera, this.canvas)
    controls.enableDamping = true
    controls.enablePan = false
    controls.enableZoom = false
    controls.maxPolarAngle = Math.PI / 2 - .1
    

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setClearColor('#211f1c')


    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let time = Date.now()
    const tick = () => {      
      const elapsedTime = clock.getElapsedTime()
      const currentTime = Date.now()
      const deltaTime = currentTime - time
      time = currentTime

      // Update controls
      controls.update()

      // Render
      renderer.render(scene, camera)

      // Call tick again on the next frame
      window.requestAnimationFrame(tick)

      group.rotation.y += 0.0001 * deltaTime
    }

    tick()

  }
}