import { Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { techStackItem } from 'src/app/models/techstack-item';

@Component({
  selector: 'app-webdev-page',
  templateUrl: './webdev-page.component.html',
  styleUrls: ['./webdev-page.component.scss','../experiences.scss']
})
export class WebdevPageComponent implements OnInit {

  web_dev: AnimationOptions = {
    path: '../../assets/lottie/hero/web_dev/data.json',
  };
  screen_size: AnimationOptions = {
    path: '../../assets/lottie/icons/screen_size/data.json',
  };
  design: AnimationOptions = {
    path: '../../assets/lottie/icons/design/data.json',
  };
  cms: AnimationOptions = {
    path: '../../assets/lottie/icons/cms/data.json',
  };
  headless_cms: AnimationOptions = {
    path: '../../assets/lottie/icons/headless_cms/data.json',
  };
  web_frameworks:AnimationOptions = {
    path: '../../assets/lottie/icons/web_frameworks/data.json',
  };
  web_shop: AnimationOptions = {
    path: '../../assets/lottie/icons/webshop/data.json'
  }


  techstack: techStackItem[] = [
    {
      logopath: '../../assets/images/tech-logos/htmllogo.webp',
      name: 'HTML5',
      url: 'https://html5.org/'
    },
    {
      logopath: '../../assets/images/tech-logos/csslogo.webp',
      name: 'CSS3',
      url: 'https://developer.mozilla.org/de/docs/Web/CSS'
    },
    {
      logopath: '../../assets/images/tech-logos/jslogo.webp',
      name: 'JavaScript',
      url: 'https://www.javascript.com/'
    },
    {
      logopath: '../../assets/images/tech-logos/tslogo.webp',
      name: 'TypeScript',
      url: 'https://www.typescriptlang.org/'
    },
    {
      logopath: '../../assets/images/tech-logos/angularlogo.webp',
      name: 'Angular',
      url: 'https://angular.io/'
    },
    {
      logopath: '../../assets/images/tech-logos/reactlogo.webp',
      name: 'React',
      url: 'https://reactjs.org/'
    },
    {
      logopath: '../../assets/images/tech-logos/vuelogo.webp',
      name: 'Vue.js',
      url: 'https://vuejs.org/'
    },
    {
      logopath: '../../assets/images/tech-logos/nodelogo.webp',
      name: 'Node.js',
      url: 'https://nodejs.org/en/'
    },
    {
      logopath: '../../assets/images/tech-logos/nestlogo.webp',
      name: 'Nest.js',
      url: 'https://nestjs.com/'
    },
    {
      logopath: '../../assets/images/tech-logos/aspnetlogo.webp',
      name: 'ASP.net',
      url: 'https://dotnet.microsoft.com/apps/aspnet'
    },
    {
      logopath: '../../assets/images/tech-logos/firebaselogo.webp',
      name: 'Firebase',
      url: 'https://firebase.google.com/'
    },
    {
      logopath: '../../assets/images/tech-logos/dockerlogo.webp',
      name: 'Docker',
      url: 'https://www.docker.com/'
    },
  ]

  private animationItem: AnimationItem;
  
  constructor(private titleService:Title, private ngZone: NgZone) { 
    this.titleService.setTitle("Webentwicklung | NBM");
  }

  ngOnInit(): void {
  }

}
