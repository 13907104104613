<div class="grid">
    <div class="item">
        <div class="img-container">
            <img [src]="current.imgPath" alt="">
        </div>
    </div>
    <div class="contact-container">
        <div *ngIf="current.tel != 0">
            <p>Für mehr Informationen rufen Sie {{current.name}} an unter <b><a [href]="'tel:'+current.tel">{{current.tel}}</a></b>.</p>
            <p>Oder schreiben Sie eine E-Mail an <b><a [href]="'mailto:'+current.mail">{{current.mail}}</a></b>.</p>
        </div>
        <div *ngIf="current.tel === 0">
            <p>Für mehr Informationen schreiben Sie eine E-Mail an <b><a [href]="'mailto:'+current.mail">{{current.mail}}</a></b>.</p>
        </div>
    </div>
</div>