<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>
                <h1>{{'animation.title'|translate}}</h1>
                <p>{{'animation.description'|translate}}</p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>

            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <app-wrapper>
        <h1>2D und 3D</h1>
        <div class="grid">
            <div class="item first">
                <ng-lottie [options]="animation2D"></ng-lottie>
                <div class="content">
                    <h2>{{'animation.section_1.title'| translate}}</h2>
                    <br>
                    <br>
                    <ul>
                        <li *ngFor="let a of 'animation.section_1.keypoints' | translate">{{a}}</li>
                    </ul>
                    <br><br>
                    <p [innerHtml]="'animation.section_1.examples'|translate"></p>
                </div>
            </div>
            <div class="item second">
                <ng-lottie [options]="animation3D"></ng-lottie>
                <div class="content three">
                    <h2>{{'animation.section_2.title'|translate}}</h2>
                    <br>
                    <br>
                    <ul>
                        <li *ngFor="let a of 'animation.section_2.keypoints' | translate">{{a}}</li>
                    </ul>
                    <br><br>
                    <p [innerHtml]="'animation.section_2.examples'|translate"></p>
                    <br>
                    <button class="btn" routerLink="/showroom">{{'animation.section_2.btn'|translate}}</button>
                </div>
            </div>
        </div>
        <br>
        <app-contact-person name="Patrick Hörtreiter"></app-contact-person>
    </app-wrapper>
   
</div>