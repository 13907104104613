import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppearDirective } from 'src/app/directives/appear.directive';
import { HeaderDirective } from 'src/app/directives/header.directive';
import { ScrollAnimationDirective } from 'src/app/directives/scroll-animation.directive';



@NgModule({
  declarations: [
    AppearDirective,
    ScrollAnimationDirective,
    HeaderDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AppearDirective,
    ScrollAnimationDirective,
    HeaderDirective
  ]
})
export class DirectivesModule { }
