<h1 class="adsimple-312054461">Datenschutzerklärung</h1>
<app-wrapper>
    <h2 id="einleitung-ueberblick" class="adsimple-312054461">Einleitung und Überblick</h2>
    <p>Wir haben diese Datenschutzerklärung (Fassung 19.06.2022-312054461) verfasst, um Ihnen gemäß der Vorgaben der <a
            class="adsimple-312054461"
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312054461#d1e2269-1-1"
            target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen
        Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von
        uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und
        welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu
        verstehen.<br />
        <strong class="adsimple-312054461">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über
        Sie verarbeiten.
    </p>
    <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
        Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich
        beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong
            class="adsimple-312054461">Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen
        geboten und <strong class="adsimple-312054461">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in
        klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten
        verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man
        möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard
        sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ
        und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
        Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
        verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten
        anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
    <h2 id="anwendungsbereich" class="adsimple-312054461">Anwendungsbereich</h2>
    <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für
        alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit
        personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
        E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
        dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der
        Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
        <li class="adsimple-312054461">Social Media Auftritte und E-Mail-Kommunikation</li>
        <li class="adsimple-312054461">mobile Apps für Smartphones und andere Geräte</li>
    </ul>
    <p>
        <strong class="adsimple-312054461">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in
        denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten
        wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert
        informieren.
    </p>
    <h2 id="rechtsgrundlagen" class="adsimple-312054461">Rechtsgrundlagen</h2>
    <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen
        und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen,
        personenbezogene Daten zu verarbeiten.<br />
        Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES
        RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf
        EUR-Lex, dem Zugang zum EU-Recht, unter <a class="adsimple-312054461"
            href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a>
        nachlesen.</p>
    <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
    <ol>
        <li class="adsimple-312054461">
            <strong class="adsimple-312054461">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns
            Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung
            Ihrer eingegebenen Daten eines Kontaktformulars.
        </li>
        <li class="adsimple-312054461">
            <strong class="adsimple-312054461">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder
            vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel
            einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.
        </li>
        <li class="adsimple-312054461">
            <strong class="adsimple-312054461">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn
            wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir
            gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel
            personenbezogene Daten.
        </li>
        <li class="adsimple-312054461">
            <strong class="adsimple-312054461">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im
            Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung
            personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und
            wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.
        </li>
    </ol>
    <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt
        sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche
        Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
    <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">In <strong class="adsimple-312054461">Österreich</strong> ist dies das
            Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong
                class="adsimple-312054461">Datenschutzgesetz</strong>), kurz <strong
                class="adsimple-312054461">DSG</strong>.</li>
        <li class="adsimple-312054461">In <strong class="adsimple-312054461">Deutschland</strong> gilt das <strong
                class="adsimple-312054461">Bundesdatenschutzgesetz</strong>, kurz<strong class="adsimple-312054461">
                BDSG</strong>.</li>
    </ul>
    <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
        Abschnitten darüber.</p>
    <h2 id="speicherdauer" class="adsimple-312054461">Speicherdauer</h2>
    <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen
        und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir
        personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen
        Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu
        speichern, zum Beispiel zu Zwecken der Buchführung.</p>
    <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die
        Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
    <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere
        Informationen dazu haben.</p>
    <h2 id="rechte-dsgvo" class="adsimple-312054461">Rechte laut Datenschutz-Grundverordnung</h2>
    <p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer
        fairen und transparenten Verarbeitung von Daten kommt:</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von
            Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die
            folgenden Informationen zu erfahren:
            <ul class="adsimple-312054461">
                <li class="adsimple-312054461">zu welchem Zweck wir die Verarbeitung durchführen;</li>
                <li class="adsimple-312054461">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
                <li class="adsimple-312054461">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt
                    werden, wie die Sicherheit garantiert werden kann;</li>
                <li class="adsimple-312054461">wie lange die Daten gespeichert werden;</li>
                <li class="adsimple-312054461">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der
                    Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
                <li class="adsimple-312054461">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu
                    diesen Behörden finden Sie weiter unten);</li>
                <li class="adsimple-312054461">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
                <li class="adsimple-312054461">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet
                    werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
            </ul>
        </li>
        <li class="adsimple-312054461">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
            bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
        <li class="adsimple-312054461">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
            Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
        <li class="adsimple-312054461">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was
            bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
        <li class="adsimple-312054461">Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet,
            dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
        <li class="adsimple-312054461">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung
            eine Änderung der Verarbeitung mit sich bringt.
            <ul class="adsimple-312054461">
                <li class="adsimple-312054461">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
                    (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes
                    Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so
                    rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
                <li class="adsimple-312054461">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
                    jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht
                    mehr für Direktmarketing verwenden.</li>
                <li class="adsimple-312054461">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit
                    gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für
                    Profiling verwenden.</li>
            </ul>
        </li>
        <li class="adsimple-312054461">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
            ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung
            unterworfen zu werden.</li>
        <li class="adsimple-312054461">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können
            sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung
            von personenbezogenen Daten gegen die DSGVO verstößt.</li>
    </ul>
    <p>
        <strong class="adsimple-312054461">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben
        gelistete verantwortliche Stelle bei uns zu kontaktieren!
    </p>
    <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
        datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
        Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a
            class="adsimple-312054461" href="https://www.dsb.gv.at/?tid=312054461" target="_blank"
            rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen
        Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a class="adsimple-312054461"
            href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Bundesbeauftragte für
            den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende
        lokale Datenschutzbehörde zuständig:</p>
    <h2 id="bayern-datenschutzbehoerde" class="adsimple-312054461">Bayern Datenschutzbehörde</h2>
    <p>
        <strong class="adsimple-312054461">Landesbeauftragter für Datenschutz:</strong> Prof. Dr. Thomas Petri<br />
        <strong class="adsimple-312054461">Adresse:</strong> Wagmüllerstr. 18, 80538 München<br />
        <strong class="adsimple-312054461">Telefonnr.:</strong> 089/21 26 72-0<br />
        <strong class="adsimple-312054461">E-Mail-Adresse:</strong> poststelle@datenschutz-bayern.de<br />
        <strong class="adsimple-312054461">Website: </strong>
        <a class="adsimple-312054461" href="https://www.datenschutz-bayern.de/?tid=312054461" target="_blank"
            rel="noopener">https://www.datenschutz-bayern.de/</a>
    </p>
    <h2 id="datenuebertragung-drittlaender" class="adsimple-312054461">Datenübertragung in Drittländer</h2>
    <p>Wir übertragen oder verarbeiten Daten nur dann in Länder außerhalb der EU (Drittländer), wenn Sie dieser
        Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder vertraglich notwendig und in jedem Fall nur
        soweit dies generell erlaubt ist. Ihre Zustimmung ist in den meisten Fällen der wichtigste Grund, dass wir Daten
        in Drittländern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in Drittländern wie den USA, wo
        viele Softwarehersteller Dienstleistungen anbieten und Ihre Serverstandorte haben, kann bedeuten, dass
        personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert werden.</p>
    <p>Wir weisen ausdrücklich darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes
        Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung durch US-Dienste (wie
        beispielsweise Google Analytics) kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und
        gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten
        nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters, sofern
        Sie ein entsprechendes Nutzerkonto haben, verknüpft werden. Nach Möglichkeit versuchen wir Serverstandorte
        innerhalb der EU zu nutzen, sofern das angeboten wird.</p>
    <p>Wir informieren Sie an den passenden Stellen dieser Datenschutzerklärung genauer über Datenübertragung in
        Drittländer, sofern diese zutrifft.</p>
    <h2 id="cookies" class="adsimple-312054461">Cookies</h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Cookies Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
                    Hersteller der Software, der das Cookie setzt.<br />
                    &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie
                    weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren
                    variieren<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was sind Cookies?</h3>
    <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
        Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
        Datenschutzerklärung besser verstehen.</p>
    <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
        Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine
        Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
    <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden
        Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche
        gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese
        Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht.
        Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder
        mehrere Attribute angegeben werden.</p>
    <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen.
        Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite
        zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt
        sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle
        Cookies in einer einzigen Datei gespeichert.</p>
    <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver.
        Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut
        verwendet, sobald eine andere Seite angefordert wird.</p>
    <p>
        <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
            alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
    </p>
    <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
        unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt.
        Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
        Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
        enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
        zugreifen.</p>
    <p>So können zum Beispiel Cookie-Daten aussehen:</p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> _ga<br />
        <strong class="adsimple-312054461">Wert:</strong> GA1.2.1326744211.152312054461-9<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">Mindestens 4096 Bytes pro Cookie</li>
        <li class="adsimple-312054461">Mindestens 50 Cookies pro Domain</li>
        <li class="adsimple-312054461">Mindestens 3000 Cookies insgesamt</li>
    </ul>
    <h3 class="adsimple-312054461">Welche Arten von Cookies gibt es?</h3>
    <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
        folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen
        Arten von HTTP-Cookies eingehen.</p>
    <p>Man kann 4 Arten von Cookies unterscheiden:</p>
    <p>
        <strong class="adsimple-312054461">Unerlässliche Cookies<br />
        </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht
        es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und
        später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein
        Browserfenster schließt.
    </p>
    <p>
        <strong class="adsimple-312054461">Zweckmäßige Cookies<br />
        </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
        Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern
        gemessen.
    </p>
    <p>
        <strong class="adsimple-312054461">Zielorientierte Cookies<br />
        </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
        Standorte, Schriftgrößen oder Formulardaten gespeichert.
    </p>
    <p>
        <strong class="adsimple-312054461">Werbe-Cookies<br />
        </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
        Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
    </p>
    <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen
        möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
    <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a
            class="adsimple-312054461"
            href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem
        Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management
        Mechanism&#8221;.</p>
    <h3 class="adsimple-312054461">Zweck der Verarbeitung über Cookies</h3>
    <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
        Hersteller der Software, die das Cookie setzt.</p>
    <h3 class="adsimple-312054461">Welche Daten werden verarbeitet?</h3>
    <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden,
        kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die
        verarbeiteten bzw. gespeicherten Daten informieren.</p>
    <h3 class="adsimple-312054461">Speicherdauer von Cookies</h3>
    <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach
        weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
    <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
        jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf
        einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der
        Speicherung bis dahin unberührt bleibt.</p>
    <h3 class="adsimple-312054461">Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
    <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
        Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur
        teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies
        zulassen.</p>
    <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen
        ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
    <p>
        <a class="adsimple-312054461" href="https://support.google.com/chrome/answer/95647?tid=312054461"
            target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
        <a class="adsimple-312054461" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=312054461"
            target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
        <a class="adsimple-312054461"
            href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=312054461"
            target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben</a>
    </p>
    <p>
        <a class="adsimple-312054461"
            href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=312054461">Internet
            Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
        <a class="adsimple-312054461"
            href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=312054461">Microsoft
            Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
        informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie
        das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die
        Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221;
        im Falle eines Chrome Browsers.</p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies
        eine <strong class="adsimple-312054461">Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen
        verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
        Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
        Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
        umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes
        (TMG).</p>
    <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen <strong
            class="adsimple-312054461">berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den
        meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme
        Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
    <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung.
        Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
    <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte
        Software Cookies verwendet.</p>
    <h2 id="webhosting-einleitung" class="adsimple-312054461">Webhosting Einleitung</h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Webhosting Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
                    &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und
                    weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting
                    Provider.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was ist Webhosting?</h3>
    <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211;
        automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur
        mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer
        Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit
        Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
    <p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein
        Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome,
        Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
    <p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website
        gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe,
        weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten
        Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine
        ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
    <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und während
        der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen.
        Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um
        einen ordentlichen Betrieb zu gewährleisten.</p>
    <p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel
        zwischen Browser, dem Internet und dem Hosting-Provider.</p>
    <p>
        <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
            alt="Browser und Webserver" width="100%" />
    </p>
    <h3 class="adsimple-312054461">Warum verarbeiten wir personenbezogene Daten?</h3>
    <p>Die Zwecke der Datenverarbeitung sind:</p>
    <ol>
        <li class="adsimple-312054461">Professionelles Hosting der Website und Absicherung des Betriebs</li>
        <li class="adsimple-312054461">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
        <li class="adsimple-312054461">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und
            ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
    </ol>
    <h3 class="adsimple-312054461">Welche Daten werden verarbeitet?</h3>
    <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem
        diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
        <li class="adsimple-312054461">Browser und Browserversion (z. B. Chrome 87)</li>
        <li class="adsimple-312054461">das verwendete Betriebssystem (z. B. Windows 10)</li>
        <li class="adsimple-312054461">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a
                class="adsimple-312054461" href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank"
                rel="follow noopener">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
        <li class="adsimple-312054461">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z.
            B. COMPUTERNAME und 194.23.43.121)</li>
        <li class="adsimple-312054461">Datum und Uhrzeit</li>
        <li class="adsimple-312054461">in Dateien, den sogenannten Webserver-Logfiles</li>
    </ul>
    <h3 class="adsimple-312054461">Wie lange werden Daten gespeichert?</h3>
    <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben
        diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem
        Verhalten von Behörden eingesehen werden.</p>
    <p>
        <strong class="adsimple-312054461">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die
        unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht
        ohne Zustimmung weiter!
    </p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs.
        1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem
        Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und
        Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
    <p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art.
        28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
    <h2 id="firebase-cloud-datenschutzerklaerung" class="adsimple-312054461">Firebase Cloud Datenschutzerklärung</h2>
    <p>Wir verwenden auf unserer Website Firebase, unter anderem ein Webhosting- und Cloud-Dienst. Dienstanbieter ist
        das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited
        (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
    <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
        Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
        kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
    <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union,
        Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
        Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard
        Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen,
        dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich
        Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn
        die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem
        Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier: <a class="adsimple-312054461"
            href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
    </p>
    <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den
        Standardvertragsklauseln entsprechen und auch für Firebase geltend sind, finden Sie unter <a
            class="adsimple-312054461"
            href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>.
    </p>
    <p>Mehr über die Daten, die durch die Verwendung von Firebase verarbeitet werden, erfahren Sie in der Privacy Policy
        auf <a class="adsimple-312054461" href="https://policies.google.com/privacy?hl=en-US?tid=312054461"
            target="_blank" rel="noopener">https://policies.google.com/privacy?hl=en-US</a>.</p>
    <h2 id="hetzner-datenschutzerklaerung" class="adsimple-312054461">Hetzner Datenschutzerklärung</h2>
    <p>Wir nutzen für unsere Website Hetzner, unter anderem ein Webhosting-Anbieter. Dienstanbieter ist das deutsche
        Unternehmen Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, Deutschland. Mehr über die Daten, die
        durch die Verwendung von Hetzner verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf <a
            class="adsimple-312054461"
            href="https://www.hetzner.com/de/legal/privacy-policy">https://www.hetzner.com/de/legal/privacy-policy</a>.
    </p>
    <h2 id="web-analytics-einleitung" class="adsimple-312054461">Web Analytics Einleitung</h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Web Analytics Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                    &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                    Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                    enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Web Analytics Tool.<br />
                    &#x1f4c5; Speicherdauer: abhängig vom eingesetzten Web-Analytics-Tool<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was ist Web Analytics?</h3>
    <p>Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics
        oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch
        Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über das
        Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich
        bieten die meisten Tools verschiedene Testmöglichkeiten an. So können wir etwa testen, welche Angebote oder
        Inhalte bei unseren Besuchern am besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei
        verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt
        unsere Websitebesucher interessanter finden. Für solche Testverfahren, wie auch für andere Analytics-Verfahren,
        können auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
    <h3 class="adsimple-312054461">Warum betreiben wir Web Analytics?</h3>
    <p>Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für unsere Branche das beste Webangebot auf
        dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot
        bieten und andererseits darauf achten, dass Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von
        Webanalyse-Tools können wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann
        entsprechend unser Webangebot für Sie und uns verbessern. So können wir beispielsweise erkennen wie alt unsere
        Besucher durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche
        Inhalte oder Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und
        somit bestens an Ihre Bedürfnisse, Interessen und Wünsche anzupassen.</p>
    <h3 class="adsimple-312054461">Welche Daten werden verarbeitet?</h3>
    <p>Welche Daten genau gespeichert werden, hängt natürlich von den verwendeten Analyse-Tools ab. Doch in der Regel
        wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie
        klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone
        usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren,
        dass auch Standortdaten erhoben werden dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet
        werden.</p>
    <p>Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen
        personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und
        gekürzter Form) gespeichert. Für den Zweck der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich
        keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese
        Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So können Sie als Person nicht
        identifiziert werden.</p>
    <p>Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel für client-basiertes
        Webtracking mit Java-Script-Code.</p>
    <p>
        <img role="img" src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg"
            alt="Schematischer Datenfluss bei Google Analytics" width="100%" />
    </p>
    <p>Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom Anbieter ab. Manche Cookies speichern Daten
        nur für ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten über mehrere
        Jahre speichern.</p>
    <h3 class="adsimple-312054461">
        <span class="adsimple-312054461"
            data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}"
            data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer
            der Datenverarbeitung</span>
    </h3>
    <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu
        haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer
        Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung,
        gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
    <h3 class="adsimple-312054461">Widerspruchsrecht</h3>
    <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
        Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem
        Browser die Cookies verwalten, deaktivieren oder löschen.</p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt
        haben. Diese Einwilligung stellt laut<strong class="adsimple-312054461"> Art. 6 Abs. 1 lit. a DSGVO
            (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der
        Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
    <p>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der
        Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von
        Web-Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit
        verbessern. Die Rechtsgrundlage dafür ist <strong class="adsimple-312054461">Art. 6 Abs. 1 lit. f DSGVO
            (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung
        erteilt haben.</p>
    <p>Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das Lesen unserer allgemeinen
        Datenschutzerklärung zu Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet
        werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
    <p>Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden
        Abschnitten.</p>
    <h2 id="google-analytics-datenschutzerklaerung" class="adsimple-312054461">Google Analytics Datenschutzerklärung
    </h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Google Analytics Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
                    &#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe,
                    Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen
                    enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                    &#x1f4c5; Speicherdauer: abhängig von den verwendeten Properties<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was ist Google Analytics?</h3>
    <p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens
        Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
        Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen
        auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert
        und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir
        unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das
        Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das
        verhindern können.</p>
    <p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics
        funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen,
        zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere
        Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
    <p>Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem
        um folgende Berichte handeln:</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser
            kennen und wissen genauer, wer sich für unser Service interessiert.</li>
        <li class="adsimple-312054461">Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter
            analysieren und verbessern.</li>
        <li class="adsimple-312054461">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen
            darüber, wie wir mehr Menschen für unser Service begeistern können.</li>
        <li class="adsimple-312054461">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren.
            Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
        <li class="adsimple-312054461">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund
            einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen
            Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr
            darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
        </li>
        <li class="adsimple-312054461">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website
            passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
    </ul>
    <h3 class="adsimple-312054461">Warum verwenden wir Google Analytics auf unserer Website?</h3>
    <p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und
        Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
    <p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website.
        Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter
        gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr
        genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten
        dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen.
        Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür
        interessieren.</p>
    <h3 class="adsimple-312054461">Welche Daten werden von Google Analytics gespeichert?</h3>
    <p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem
        Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere
        Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit
        dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
    <p>Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code
        eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte
        Property ist die Google Analytics 4-Property standardmäßig. Alternativ kann man aber auch noch die Universal
        Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
    <p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen.
        Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere
        Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit
        Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als
        Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
    <p>Folgende Cookies werden von Google Analytics verwendet:</p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> _ga<br />
        <strong class="adsimple-312054461">Wert: </strong>2.1326744211.152312054461-5<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie
        _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> _gid<br />
        <strong class="adsimple-312054461">Wert: </strong>2.1687193234.152312054461-1<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der
        Webseitenbesucher<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 24 Stunden
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
        <strong class="adsimple-312054461">Wert:</strong> 1<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet.
        Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
        &lt;property-id&gt;.<br />
        <strong class="adsimple-312054461">Ablaufdatum: </strong>nach 1 Minute
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> AMP_TOKEN<br />
        <strong class="adsimple-312054461">Wert:</strong> keine Angaben<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID
        vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage
        oder einen Fehler hin.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> __utma<br />
        <strong class="adsimple-312054461">Wert: </strong>1564498958.1564498958.1564498958.1<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der
        Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google
        Analytics gesendet werden.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> __utmt<br />
        <strong class="adsimple-312054461">Wert:</strong> 1<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Das Cookie wird wie
        _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate verwendet.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 10 Minuten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> __utmb<br />
        <strong class="adsimple-312054461">Wert: </strong>3.10.1564498958<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu
        bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 30 Minuten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> __utmc<br />
        <strong class="adsimple-312054461">Wert:</strong> 167421564<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen
        für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange
        gespeichert, bis Sie den Browser wieder schließen.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> Nach Schließung des Browsers
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> __utmz<br />
        <strong class="adsimple-312054461">Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des
        Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere
        Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 6 Monaten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> __utmv<br />
        <strong class="adsimple-312054461">Wert:</strong> keine Angabe<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte
        Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet
        werden.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit
        erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.
    </p>
    <p>Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
    <p>
        <strong class="adsimple-312054461">Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht
        man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“
        sind.
    </p>
    <p>
        <strong class="adsimple-312054461">Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie
        auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung
        automatisch.
    </p>
    <p>
        <strong class="adsimple-312054461">Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede,
        wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.
    </p>
    <p>
        <strong class="adsimple-312054461">Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen
        bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.
    </p>
    <p>
        <strong class="adsimple-312054461">IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt,
        damit keine eindeutige Zuordnung möglich ist.
    </p>
    <p>
        <strong class="adsimple-312054461">Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer
        Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.
    </p>
    <p>
        <strong class="adsimple-312054461">Technische Informationen:</strong> Zu den technischen Informationen zählen
        unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.
    </p>
    <p>
        <strong class="adsimple-312054461">Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert
        natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.
    </p>
    <p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z. B. wenn Sie ein Video über
        unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die
        Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der
        Datenspeicherung durch Google Analytics.</p>
    <h3 class="adsimple-312054461">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Google hat ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich
        werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die
        Google-Rechenzentren befinden: <a class="adsimple-312054461"
            href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
    </p>
    <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten
        schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es
        entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder
        Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
    <p>Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der Verwendung der neueren Google
        Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere
        sogenannte Ereignisdaten haben wir die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu
        wählen.</p>
    <p>Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26
        Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit,
        die Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">Löschung nach 14 Monaten</li>
        <li class="adsimple-312054461">Löschung nach 26 Monaten</li>
        <li class="adsimple-312054461">Löschung nach 38 Monaten</li>
        <li class="adsimple-312054461">Löschung nach 50 Monaten</li>
        <li class="adsimple-312054461">Keine automatische Löschung</li>
    </ul>
    <p>Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns
        gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal
        zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
    <p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer
        gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain)
        verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten
        gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
    <h3 class="adsimple-312054461">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie
        zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google
        Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet.
        Das Browser-Add-on können Sie unter <a class="adsimple-312054461"
            href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
            rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren.
        Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
    <p>Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten wollen, finden Sie unter dem Abschnitt
        „Cookies“ die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt
        haben. Diese Einwilligung stellt laut<strong class="adsimple-312054461"> Art. 6 Abs. 1 lit. a DSGVO
            (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der
        Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
    <p>Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der
        Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von
        Google Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit
        verbessern. Die Rechtsgrundlage dafür ist <strong class="adsimple-312054461">Art. 6 Abs. 1 lit. f DSGVO
            (Berechtigte Interessen)</strong>. Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine
        Einwilligung erteilt haben.</p>
    <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
        Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
        kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
    <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union,
        Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
        Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard
        Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen,
        dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich
        Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn
        die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem
        Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier: <a class="adsimple-312054461"
            href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank"
            rel="noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
    </p>
    <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den
        Standardvertragsklauseln entsprechen und auch für Google Analytics geltend sind, finden Sie unter <a
            class="adsimple-312054461"
            href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>.
    </p>
    <p>Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics
        näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a
            class="adsimple-312054461"
            href="https://marketingplatform.google.com/about/analytics/terms/de/">https://marketingplatform.google.com/about/analytics/terms/de/</a>
        und <a class="adsimple-312054461" href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
    <h2 id="google-analytics-im-einwilligungsmodus" class="adsimple-312054461">Google Analytics im Einwilligungsmodus
    </h2>
    <p>Abhängig von Ihrer Einwilligung werden im sogenannten Einwilligungsmodus (bzw. „Consent Mode“) personenbezogene
        Daten von Ihnen durch Google Analytics verarbeitet. Sie können wählen, ob Sie Google-Analytics-Cookies zustimmen
        oder nicht. Damit wählen Sie auch, welche Daten Google Analytics von Ihnen verarbeitet darf. Diese erhobenen
        Daten werden hauptsächlich dafür verwendet, Messungen über das Userverhalten auf der Website durchzuführen,
        zielgerichtete Werbung auszuspielen und uns Web-Analyseberichte zu liefern. In der Regel willigen Sie der
        Datenverarbeitung durch Google über ein Cookie-Consent-Tool ein. Wenn Sie der Datenverarbeitung nicht
        einwilligen, werden nur aggregierte Daten erfasst und verarbeitet. Das bedeutet, Daten können einzelnen Usern
        nicht zugeordnet werden und es entsteht somit kein Userprofil von Ihnen. Sie können auch nur der statistischen
        Messung zustimmen. Dabei werden keine personenbezogenen Daten verarbeitet und folglich nicht für Werbungen oder
        Werbemesserfolge verwendet.</p>
    <h2 id="security-anti-spam" class="adsimple-312054461">Security &amp; Anti-Spam</h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Security &amp; Anti-Spam Datenschutzerklärung
                        Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Cybersicherheit<br />
                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse, Name oder technische Daten wie etwa
                    Browserversion<br />
                    Mehr Details dazu finden Sie weiter unten und den einzelnen Datenschutztexten.<br />
                    &#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der
                    Dienstleistung nicht mehr benötigt werden<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was ist eine Security- &amp; Anti-Spam-Software?</h3>
    <p>Mit sogenannten Security- &amp; Anti-Spam-Softwares können Sie sich und wir uns vor diversen Spam- oder
        Phishing-Mails und möglichen anderen Cyberattacken schützen. Unter Spam versteht man Werbemails aus einem
        Massenversand, die man selbst nicht verlangte. Solche Mails werden auch Datenmüll genannt und können auch Kosten
        verursachen. Phishing-Mails sind wiederum Nachrichten, die darauf abzielen, über gefälschte Nachrichten oder
        Websites Vertrauen aufzubauen, um an persönliche Daten zu gelangen. Eine Anti-Spam-Software schützt in der Regel
        vor unerwünschten Spam-Nachrichten oder bösartigen Mails, die etwa Viren in unser System einschleusen könnten.
        Wir nutzen auch allgemeine Firewall- und Sicherheitssysteme, die unsere Computer vor unerwünschten
        Netzwerkangriffen schützen.</p>
    <h3 class="adsimple-312054461">Warum verwenden wir Security- &amp; Anti-Spam-Software?</h3>
    <p>Wir legen auf unserer Website besonders großen Wert auf Sicherheit. Schließlich geht es nicht nur um unsere,
        sondern vor allem auch um Ihre Sicherheit. Leider gehören mittlerweile in der Welt der IT und des Internets
        Cyberbedrohungen schon zum Alltag. Oft versuchen Hacker mit Hilfe einer Cyberattacke personenbezogene Daten aus
        einem IT-System zu stehlen. Und daher ist ein gutes Abwehrsystem absolut notwendig. Ein Sicherheitssystem
        überwacht alle ein- und ausgehenden Verbindungen zu unserem Netzwerk bzw. Computer. Damit wir noch größere
        Sicherheit vor Cyberangriffen erreichen, nutzen wir neben den standardisierten Sicherheitssystemen auf unserem
        Computer auch noch weitere externe Security-Dienste. Unerlaubter Verkehr von Daten wird dadurch besser
        unterbunden und so schützen wir uns vor Cyberkriminalität.</p>
    <h3 class="adsimple-312054461">Welche Daten werden durch Security- &amp; Anti-Spam-Softwares verarbeitet?</h3>
    <p>Welche Daten genau erhoben und gespeichert werden hängt natürlich vom jeweiligen Dienst ab. Wir sind allerdings
        stets bemüht nur Programme zu verwenden, die sehr sparsam Daten erheben bzw. nur Daten speichern, die für die
        Erfüllung der angebotenen Leistung nötig sind. Grundsätzlich kann der Dienst Daten wie beispielsweise Name,
        Adresse, IP-Adresse, E-Mail-Adresse und technische Daten wie Browsertyp oder Browserversion speichern. Auch
        können etwaige Leistungs- und Protokolldaten erhoben werden, um mögliche eingehende Bedrohungen rechtzeitig zu
        erkennen. Diese Daten werden im Rahmen der Services und unter Einhaltung der geltenden Gesetze verarbeitet. Dazu
        zählt auch bei US-amerikanischen Anbietern (über die Standardvertragsklauseln) die DSGVO. Diese Security-Dienste
        arbeiten in einigen Fällen auch mit Drittanbietern zusammen, die unter Anweisung und in Übereinstimmung mit den
        Datenschutzrichtlinien und weiteren Sicherheitsmaßnahmen Daten speichern und/oder verarbeiten können. Die
        Datenspeicherung erfolgt meist über Cookies.</p>
    <h3 class="adsimple-312054461">Dauer der Datenverarbeitung</h3>
    <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu
        haben. Beispielsweise speichern Security-Programme Daten bis Sie oder wir die Datenspeicherung widerrufen.
        Generell werden personenbezogene Daten nur so lange, wie es für die Bereitstellung der Dienstleistungen
        unbedingt notwendig ist, gespeichert. In vielen Fällen fehlen uns leider von den Anbietern präzise Informationen
        über die Länge der Speicherung.</p>
    <h3 class="adsimple-312054461">Widerspruchsrecht</h3>
    <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
        Drittanbietern von Security-Software zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool
        oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern,
        indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
    <p>Da bei solchen Sicherheitsdiensten auch Cookies zum Einsatz kommen können, empfehlen wir Ihnen unsere allgemeine
        Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet
        werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Wir setzen die Sicherheitsdienste hauptsächlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit.
        f DSGVO) an einem guten Sicherheitssystem gegen diverse Cyberangriffe ein.</p>
    <p>Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Sicherheitsfunktionen
        bedürfen Ihrer Einwilligung. Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene
        Security-Dienste verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der
        Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO). Die meisten von uns verwendeten Dienste setzen Cookies in Ihrem
        Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau
        durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.
    </p>
    <p>Informationen zu speziellen Tools erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
    <h2 id="google-recaptcha-datenschutzerklaerung" class="adsimple-312054461">Google reCAPTCHA Datenschutzerklärung
    </h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Google reCAPTCHA Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung und Schutz vor Cyberangriffen<br />
                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse, Browserinformationen, Ihr Betriebssystem,
                    eingeschränkte Standorts- und Nutzungsdaten<br />
                    Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                    &#x1f4c5; Speicherdauer: abhängig von den gespeicherten Daten<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was ist reCAPTCHA?</h3>
    <p>Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns bestmöglich zu sichern und zu schützen. Um das zu
        gewährleisten, verwenden wir Google reCAPTCHA der Firma Google Inc. Für den europäischen Raum ist das
        Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
        verantwortlich. Mit reCAPTCHA können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch und Blut sind
        und kein Roboter oder eine andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg,
        unerwünschte Information, die uns ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur
        Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen
        Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie einfach ein Häkchen setzen und so bestätigen,
        dass Sie kein Bot sind. Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen setzen.
        Wie das genau funktioniert und vor allem welche Daten dafür verwendet werden, erfahren Sie im Verlauf dieser
        Datenschutzerklärung.</p>
    <p>reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den Missbrauch durch
        nicht-menschliche Besucher schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie Formulare im Internet
        ausfüllen. Ein Captcha-Dienst ist eine Art automatischer Turing-Test, der sicherstellen soll, dass eine Handlung
        im Internet von einem Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt
        nach dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest. Bei
        Captchas übernimmt das auch der Computer bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen
        Aufgaben, die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche Schwierigkeiten aufweisen. Bei
        reCAPTCHA müssen Sie aktiv keine Rätsel mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen von
        Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich bin kein Roboter“ ankreuzen bzw. bei Invisible
        reCAPTCHA ist selbst das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext
        eingebunden und dann läuft das Tool im Hintergrund und analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen
        berechnet die Software einen sogenannten Captcha-Score. Google berechnet mit diesem Score schon vor der
        Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw. Captchas im
        Allgemeinen kommen immer dann zum Einsatz, wenn Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.)
        manipulieren oder missbrauchen könnten.</p>
    <h3 class="adsimple-312054461">Warum verwenden wir reCAPTCHA auf unserer Website?</h3>
    <p>Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen. Bots oder Spam-Software
        unterschiedlichster Art dürfen getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu schützen
        und die bestmögliche Benutzerfreundlichkeit für Sie anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA
        der Firma Google. So können wir uns ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben. Durch die
        Verwendung von reCAPTCHA werden Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich ein Mensch
        sind. reCAPTCHA dient also der Sicherheit unserer Webseite und in weiterer Folge damit auch Ihrer Sicherheit.
        Zum Beispiel könnte es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot möglichst viele
        E-Mail-Adressen registriert, um im Anschluss Foren oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit
        reCAPTCHA können wir solche Botangriffe vermeiden.</p>
    <h3 class="adsimple-312054461">Welche Daten werden von reCAPTCHA gespeichert?</h3>
    <p>reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen auf unserer Webseite auch
        wirklich von Menschen stammen. Es kann also die IP-Adresse und andere Daten, die Google für den reCAPTCHA-Dienst
        benötigt, an Google versendet werden. IP-Adressen werden innerhalb der Mitgliedstaaten der EU oder anderer
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt, bevor die Daten
        auf einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google kombiniert, sofern
        Sie nicht während der Verwendung von reCAPTCHA mit Ihrem Google-Konto angemeldet sind. Zuerst prüft der
        reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von anderen Google-Diensten (YouTube. Gmail
        usw.) platziert sind. Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser und erfasst einen
        Schnappschuss Ihres Browserfensters.</p>
    <p>Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf Vollständigkeit. Vielmehr
        sind es Beispiele von Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.</p>
    <ul class="adsimple-312054461">
        <li class="adsimple-312054461">Referrer URL (die Adresse der Seite von der der Besucher kommt)</li>
        <li class="adsimple-312054461">IP-Adresse (z.B. 256.123.123.1)</li>
        <li class="adsimple-312054461">Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers
            ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)</li>
        <li class="adsimple-312054461">Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)</li>
        <li class="adsimple-312054461">Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur
            ausführen wird gespeichert)</li>
        <li class="adsimple-312054461">Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC
            voreingestellt haben wird gespeichert)</li>
        <li class="adsimple-312054461">Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten
            ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten unter einem Namen
            sammeln)</li>
        <li class="adsimple-312054461">Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)
        </li>
    </ul>
    <p>Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Sie auf das Häkchen „Ich bin kein
        Roboter“ klicken. Bei der Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der ganze
        Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche Daten Google genau speichert, erfährt man von
        Google nicht im Detail.</p>
    <p>Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir uns auf die reCAPTCHA Demo-Version von
        Google unter <a class="adsimple-312054461" href="https://www.google.com/recaptcha/api2/demo" target="_blank"
            rel="noopener noreferrer">https://www.google.com/recaptcha/api2/demo</a>. All diese Cookies benötigen zu
        Trackingzwecken eine eindeutige Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der
        Demo-Version gesetzt hat:</p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> IDE<br />
        <strong
            class="adsimple-312054461">Wert:</strong> WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-312054461-8<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie wird von der Firma DoubleClick
        (gehört auch Google) gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit Werbeanzeigen zu
        registrieren und zu melden. So kann die Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen
        getroffen werden. IDE wird in Browsern unter der Domain doubleclick.net gespeichert.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach einem Jahr
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> 1P_JAR<br />
        <strong class="adsimple-312054461">Wert:</strong> 2019-5-14-12<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie sammelt Statistiken zur
        Webseite-Nutzung und misst Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem Käufer wird. Das
        Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie
        vermeiden, dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach einem Monat
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> ANID<br />
        <strong class="adsimple-312054461">Wert:</strong> U7j1v3dZa3120544610xgZFmiqWppRWKOr<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Viele Infos konnten wir über dieses Cookie nicht
        in Erfahrung bringen. In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang mit „Werbecookies“
        wie z. B. &#8220;DSID&#8221;, &#8220;FLC&#8221;, &#8220;AID&#8221;, &#8220;TAID&#8221; erwähnt. ANID wird unter
        Domain google.com gespeichert.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 9 Monaten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> CONSENT<br />
        <strong class="adsimple-312054461">Wert: </strong>YES+AT.de+20150628-20-0<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung
        eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu
        überprüfen, Betrügereien von Anmeldeinformationen zu verhindern und Userdaten vor unbefugten Angriffen zu
        schützen.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 19 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> NID<br />
        <strong class="adsimple-312054461">Wert:</strong> 0WmuWqy312054461zILzqV_nmt3sDXwPeM5Q<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an
        Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist eingegebenen
        Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen.
        Das Cookie enthält eine einzigartige ID, um persönliche Einstellungen des Users für Werbezwecke zu
        sammeln.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 6 Monaten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> DV<br />
        <strong class="adsimple-312054461">Wert:</strong> gEAABBCjJMXcI0dSAAAANbqc312054461-4<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Sobald Sie das „Ich bin kein Roboter“-Häkchen
        angekreuzt haben, wird dieses Cookie gesetzt. Das Cookie wird von Google Analytics für personalisierte Werbung
        verwendet. DV sammelt Informationen in anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen zu
        treffen.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 10 Minuten
    </p>
    <p>
        <strong class="adsimple-312054461">Anmerkung: </strong>Diese Aufzählung kann keinen Anspruch auf Vollständigkeit
        erheben, da Google erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.
    </p>
    <h3 class="adsimple-312054461">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den Google-Server übertragen. Wo genau diese Daten
        gespeichert werden, stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine Bestätigung
        von Google erhalten zu haben, ist davon auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der
        Webseite oder Spracheinstellungen auf den europäischen oder amerikanischen Google-Servern gespeichert werden.
        Die IP-Adresse, die Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus
        weiteren Google-Diensten zusammengeführt. Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei
        Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt.<strong
            class="adsimple-312054461"> </strong>Dafür gelten die abweichenden Datenschutzbestimmungen der Firma Google.
    </p>
    <h3 class="adsimple-312054461">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an Google übermittelt werden, müssen Sie sich,
        bevor Sie unsere Webseite besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen ausloggen und
        alle Google-Cookies löschen. Grundsätzlich werden die Daten sobald Sie unsere Seite aufrufen automatisch an
        Google übermittelt. Um diese Daten wieder zu löschen, müssen Sie den Google-Support auf  <a
            class="adsimple-312054461" href="https://support.google.com/?hl=de&amp;tid=312054461" target="_blank"
            rel="noopener noreferrer">https://support.google.com/?hl=de&amp;tid=312054461</a> kontaktieren.</p>
    <p>Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden, dass Google LLC und deren Vertreter
        automatisch Daten erheben, bearbeiten und nutzen.</p>
    <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und
        verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen
        Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort
        gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln)
        zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Wenn Sie eingewilligt haben, dass Google reCAPTCHA eingesetzt werden darf, ist die Rechtsgrundlage der
        entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
            class="adsimple-312054461"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die
        Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google reCAPTCHA vorkommen kann, dar.</p>
    <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google reCAPTCHA zu verwenden, um unser
        Online-Service zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong
            class="adsimple-312054461">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google
        reCAPTCHA gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
    <p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des
        Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies
        kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
    <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union,
        Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
        Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard
        Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen,
        dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie
        beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich
        Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn
        die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem
        Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden
        Standardvertragsklauseln u.a. hier: <a class="adsimple-312054461"
            href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank"
            rel="noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
    </p>
    <p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den
        Standardvertragsklauseln entsprechen, finden Sie unter <a class="adsimple-312054461"
            href="https://business.safety.google/adsprocessorterms/" target="_blank"
            rel="noopener">https://business.safety.google/adsprocessorterms/</a>.</p>
    <p>Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von Google auf <a class="adsimple-312054461"
            href="https://developers.google.com/recaptcha/" target="_blank"
            rel="noopener noreferrer">https://developers.google.com/recaptcha/</a>. Google geht hier zwar auf die
        technische Entwicklung der reCAPTCHA näher ein, doch genaue Informationen über Datenspeicherung und
        datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute Übersicht über die grundsätzliche
        Verwendung von Daten bei Google finden Sie in der hauseigenen Datenschutzerklärung auf <a
            class="adsimple-312054461" href="https://policies.google.com/privacy?hl=de&amp;tid=312054461"
            target="_blank" rel="noopener noreferrer">https://www.google.com/intl/de/policies/privacy/</a>.</p>
    <h2 id="audio-video-einleitung" class="adsimple-312054461">Audio &amp; Video Einleitung</h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Audio &amp; Video Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen
                    zu Ihrem Gerät und Ihre IP-Adresse können gespeichert werden.<br />
                    Mehr Details dazu finden Sie weiter unten in den entsprechenden Datenschutztexten.<br />
                    &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange sie für den Dienstzweck
                    nötig sind<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was sind Audio- und Videoelemente?</h3>
    <p>Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden, damit Sie sich direkt über unsere Website
        etwa Videos ansehen oder Musik/Podcasts anhören können. Die Inhalte werden von Dienstanbietern zur Verfügung
        gestellt. Alle Inhalte werden also auch von den entsprechenden Servern der Anbieter bezogen.</p>
    <p>Es handelt sich dabei um eingebundene Funktionselemente von Plattformen wie etwa YouTube, Vimeo oder Spotify. Die
        Nutzung dieser Portale ist in der Regel kostenlos, es können aber auch kostenpflichtige Inhalte veröffentlicht
        werden. Mit Hilfe dieser eingebundenen Elemente könne Sie sich über unsere Website die jeweiligen Inhalte
        anhören oder ansehen.</p>
    <p>Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, können auch personenbezogene Daten von Ihnen an
        die Dienstanbieter übermittelt, verarbeitet und gespeichert werden.</p>
    <h3 class="adsimple-312054461">Warum verwenden wir Audio- &amp; Videoelemente auf unserer Website?</h3>
    <p>Natürlich wollen wir Ihnen auf unserer Website das beste Angebot liefern. Und uns ist bewusst, dass Inhalte nicht
        mehr bloß in Text und statischem Bild vermittelt werden. Statt Ihnen einfach nur einen Link zu einem Video zu
        geben, bieten wir Ihnen direkt auf unserer Website Audio- und Videoformate, die unterhaltend oder informativ und
        im Idealfall sogar beides sind. Das erweitert unser Service und erleichtert Ihnen den Zugang zu interessanten
        Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video und/oder Audio-Inhalte an.</p>
    <h3 class="adsimple-312054461">Welche Daten werden durch Audio- &amp; Videoelemente gespeichert?</h3>
    <p>Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein eingebettetes Video hat, verbindet sich
        Ihr Server mit dem Server des Dienstanbieters. Dabei werden auch Daten von Ihnen an den Drittanbieter übertragen
        und dort gespeichert. Manche Daten werden ganz unabhängig davon, ob Sie bei dem Drittanbieter ein Konto haben
        oder nicht, gesammelt und gespeichert. Dazu zählen meist Ihre IP-Adresse, Browsertyp, Betriebssystem, und
        weitere allgemeine Informationen zu Ihrem Endgerät. Weiters werden von den meisten Anbietern auch Informationen
        über Ihre Webaktivität eingeholt. Dazu zählen etwa Sitzungsdauer, Absprungrate, auf welchen Button Sie geklickt
        haben oder über welche Website Sie den Dienst nutzen. All diese Informationen werden meist über Cookies oder
        Pixel-Tags (auch Web Beacon genannt) gespeichert. Pseudonymisierte Daten werden meist in Cookies in Ihrem
        Browser gespeichert. Welche Daten genau gespeichert und verarbeitet werden, erfahren Sie stets in der
        Datenschutzerklärung des jeweiligen Anbieters.</p>
    <h3 class="adsimple-312054461">Dauer der Datenverarbeitung</h3>
    <p>Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert werden, erfahren Sie entweder weiter
        unten im Datenschutztext des jeweiligen Tools oder in der Datenschutzerklärung des Anbieters. Grundsätzlich
        werden personenbezogene Daten immer nur so lange verarbeitet, wie es für die Bereitstellung unserer
        Dienstleistungen oder Produkte unbedingt nötig wird. Dies gilt in der Regel auch für Drittanbieter. Meist können
        Sie davon ausgehen, dass gewisse Daten über mehrere Jahre auf den Servern der Drittanbieter gespeichert werden.
        Daten können speziell in Cookies unterschiedlich lange gespeichert werden. Manche Cookies werden bereits nach
        dem Verlassen der Website wieder gelöscht, anderen können über einige Jahre in Ihrem Browser gespeichert sein.
    </p>
    <h3 class="adsimple-312054461">Widerspruchsrecht</h3>
    <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
        Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem
        Browser die Cookies verwalten, deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum Widerruf
        bleibt unberührt.</p>
    <p>Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite meist auch Cookies verwendet werden,
        sollte Sie sich auch unsere allgemeine Datenschutzerklärung über Cookies durchlesen. In den
        Datenschutzerklärungen der jeweiligen Drittanbieter erfahren Sie genaueres über den Umgang und die Speicherung
        Ihrer Daten.</p>
    <h3 class="adsimple-312054461">
        <strong class="adsimple-312054461">Rechtsgrundlage</strong>
    </h3>
    <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Audio- und Video-Elemente verarbeitet und
        gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
            class="adsimple-312054461">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf
        Grundlage unseres berechtigten Interesses <strong class="adsimple-312054461">(Art. 6 Abs. 1 lit. f
            DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und
        Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen Audio- und Video-Elemente gleichwohl
        nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
    <h2 id="youtube-datenschutzerklaerung" class="adsimple-312054461">YouTube Datenschutzerklärung</h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">YouTube Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
                    &#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen
                    zu Ihrem Gerät und Ihre IP-Adresse können gespeichert werden.<br />
                    Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
                    &#x1f4c5; Speicherdauer: Daten bleiben grundsätzlich gespeichert, solange sie für den Dienstzweck
                    nötig sind<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was ist YouTube?</h3>
    <p>Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf
        unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist.
        Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf
        unserer Website eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser
        automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten
        übertragen. Für die gesamte Datenverarbeitung im europäischen Raum ist Google Ireland Limited (Gordon House,
        Barrow Street Dublin 4, Irland) verantwortlich.</p>
    <p>Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos
        eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.</p>
    <p>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die
        letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf unserer
        Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut
        haben.</p>
    <h3 class="adsimple-312054461">Warum verwenden wir YouTube-Videos auf unserer Website?</h3>
    <p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die
        bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei nicht
        fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern weiteren
        hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die
        eingebetteten Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google – dank
        der gesammelten Daten – diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote interessieren.
    </p>
    <h3 class="adsimple-312054461">Welche Daten werden von YouTube gespeichert?</h3>
    <p>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein Cookie,
        das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube Ihre
        Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie
        Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie Browsertyp, Bildschirmauflösung
        oder Ihr Internetanbieter. Weitere Daten können Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über
        Social Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.</p>
    <p>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit einer
        eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise Ihre
        bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden, da
        weniger Cookies gesetzt werden.</p>
    <p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits
        Cookies, die ohne angemeldetes YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit
        angemeldetem Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die Userdaten
        immer von den Interaktionen auf YouTube abhängen.</p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> YSC<br />
        <strong class="adsimple-312054461">Wert:</strong> b9-CV6ojI5Y312054461-1<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um
        Statistiken des gesehenen Videos zu speichern.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach Sitzungsende
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> PREF<br />
        <strong class="adsimple-312054461">Wert:</strong> f1=50000000<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre
        eindeutige ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
        verwenden.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 8 Monaten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> GPS<br />
        <strong class="adsimple-312054461">Wert:</strong> 1<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf
        mobilen Geräten, um den GPS-Standort zu tracken.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 30 Minuten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> VISITOR_INFO1_LIVE<br />
        <strong class="adsimple-312054461">Wert:</strong> 95Chz8bagyU<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users
        auf unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 8 Monaten
    </p>
    <p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:</p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> APISID<br />
        <strong class="adsimple-312054461">Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7312054461-<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über
        Ihre Interessen zu erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> CONSENT<br />
        <strong class="adsimple-312054461">Wert:</strong> YES+AT.de+20150628-20-0<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung
        eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu
        überprüfen und Userdaten vor unbefugten Angriffen zu schützen.<br />
        <strong class="adsimple-312054461">Ablaufdatum: </strong>nach 19 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> HSID<br />
        <strong class="adsimple-312054461">Wert:</strong> AcRwpgUik9Dveht0I<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über
        Ihre Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu können.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> LOGIN_INFO<br />
        <strong class="adsimple-312054461">Wert:</strong> AFmmF2swRQIhALl6aL…<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> In diesem Cookie werden Informationen über Ihre
        Login-Daten gespeichert.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> SAPISID<br />
        <strong class="adsimple-312054461">Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es Ihren Browser
        und Ihr Gerät eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre Interessen zu erstellen.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> SID<br />
        <strong class="adsimple-312054461">Wert:</strong> oQfNKjAsI312054461-<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Google-Konto-ID und
        Ihren letzten Anmeldezeitpunkt in digital signierter und verschlüsselter Form.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 2 Jahren
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> SIDCC<br />
        <strong class="adsimple-312054461">Wert:</strong> AN0-TYuqub2JOcDTyL<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie Sie die
        Webseite nutzen und welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise gesehen haben.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 3 Monaten
    </p>
    <h3 class="adsimple-312054461">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die meisten
        dieser Server befinden sich in Amerika. Unter <a class="adsimple-312054461"
            href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
         sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind
        die Daten schneller abrufbar und vor Manipulation besser geschützt.</p>
    <p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere
        werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit
        gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem
        Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in einem
        Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind,
        löschen.</p>
    <h3 class="adsimple-312054461">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatischen
        Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung &#8211;
        entweder 3 oder 18 Monate gespeichert und dann gelöscht.</p>
    <p>Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies von
        Google gelöscht bzw. deaktiviert werden. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf
        unterschiedliche Art und Weise. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den
        jeweiligen Anleitungen der bekanntesten Browser.</p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
        informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es
        erlauben oder nicht.</p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene YouTube-Elemente verarbeitet und gespeichert
        werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong
            class="adsimple-312054461">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf
        Grundlage unseres berechtigten Interesses <strong class="adsimple-312054461">(Art. 6 Abs. 1 lit. f
            DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und
        Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen YouTube-Elemente gleichwohl nur ein,
        soweit Sie eine Einwilligung erteilt haben. YouTube setzt auch Cookies in Ihrem Browser, um Daten zu speichern.
        Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung
        oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
    <p>YouTube verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen
        Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit
        verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
    <p>Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union,
        Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet
        YouTube von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese
        Klauseln verpflichten YouTube, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb der
        EU einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den
        Beschluss sowie die Klauseln u.a. hier: <a class="adsimple-312054461"
            href="https://germany.representation.ec.europa.eu/index_de" target="_blank"
            rel="follow noopener">https://germany.representation.ec.europa.eu/index_de</a>.</p>
    <p>Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame Datenschutzerklärung. Wenn Sie mehr
        über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a
            class="adsimple-312054461" href="https://policies.google.com/privacy?hl=de" target="_blank"
            rel="noopener noreferrer">https://policies.google.com/privacy?hl=de.</a>
    </p>
    <h2 id="youtube-abonnieren-button" class="adsimple-312054461">YouTube Abonnieren Button Datenschutzerklärung</h2>
    <p>Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl. „Subscribe-Button“) eingebaut. Sie erkennen
        den Button meist am klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in weißer Schrift die Wörter
        „Abonnieren“ oder „YouTube“ und links davon das weiße „Play-Symbol“. Der Button kann aber auch in einem anderen
        Design dargestellt sein.</p>
    <p>Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante oder spannende Videos. Mit dem eingebauten
        „Abonnieren-Button“ können Sie unseren Kanal direkt von unserer Webseite aus abonnieren und müssen nicht eigens
        die YouTube-Webseite aufrufen. Wir wollen Ihnen somit den Zugang zu unserem umfassenden Content so einfach wie
        möglich machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen speichern und verarbeiten kann.</p>
    <p>Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt YouTube &#8211; laut Google &#8211;
        mindestens ein Cookie. Dieses Cookie speichert Ihre IP-Adresse und unsere URL. Auch Informationen über Ihren
        Browser, Ihren ungefähren Standort und Ihre voreingestellte Sprache kann YouTube so erfahren. Bei unserem Test
        wurden folgende vier Cookies gesetzt, ohne bei YouTube angemeldet zu sein:</p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> YSC<br />
        <strong class="adsimple-312054461">Wert:</strong> b9-CV6ojI5312054461Y<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um
        Statistiken des gesehenen Videos zu speichern.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach Sitzungsende
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> PREF<br />
        <strong class="adsimple-312054461">Wert:</strong> f1=50000000<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre
        eindeutige ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite
        verwenden.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 8 Monate
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> GPS<br />
        <strong class="adsimple-312054461">Wert:</strong> 1<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf
        mobilen Geräten, um den GPS-Standort zu tracken.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 30 Minuten
    </p>
    <p>
        <strong class="adsimple-312054461">Name:</strong> VISITOR_INFO1_LIVE<br />
        <strong class="adsimple-312054461">Wert:</strong> 31205446195Chz8bagyU<br />
        <strong class="adsimple-312054461">Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users
        auf unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.<br />
        <strong class="adsimple-312054461">Ablaufdatum:</strong> nach 8 Monaten
    </p>
    <p>
        <strong class="adsimple-312054461">Anmerkung:</strong> Diese Cookies wurden nach einem Test gesetzt und können
        nicht den Anspruch auf Vollständigkeit erheben.
    </p>
    <p>Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele Ihrer Handlungen/Interaktionen auf unserer
        Webseite mit Hilfe von Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt dadurch zum Beispiel
        Informationen wie lange Sie auf unserer Seite surfen, welchen Browsertyp Sie verwenden, welche
        Bildschirmauflösung Sie bevorzugen oder welche Handlungen Sie ausführen.</p>
    <p>YouTube verwendet diese Daten zum einen um die eigenen Dienstleistungen und Angebote zu verbessern, zum anderen
        um Analysen und Statistiken für Werbetreibende (die Google Ads verwenden) bereitzustellen.</p>
    <h2 id="webdesign-einleitung" class="adsimple-312054461">Webdesign Einleitung</h2>
    <table border="1" cellpadding="15">
        <tbody>
            <tr>
                <td>
                    <strong class="adsimple-312054461">Webdesign Datenschutzerklärung Zusammenfassung</strong>
                    <br />
                    &#x1f465; Betroffene: Besucher der Website<br />
                    &#x1f91d; Zweck: Verbesserung der Nutzererfahrung<br />
                    &#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten
                    Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen, 
                    Browserversion, Bildschirmauflösung und Name des Browsers. Mehr Details dazu finden Sie bei den
                    jeweils eingesetzten Webdesign-Tools.<br />
                    &#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools<br />
                    &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                    DSGVO (Berechtigte Interessen)
                </td>
            </tr>
        </tbody>
    </table>
    <h3 class="adsimple-312054461">Was ist Webdesign?</h3>
    <p>Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es nicht,
        wie oft angenommen, nur darum, dass unsere Website hübsch aussieht, sondern auch um Funktionalität und Leistung.
        Aber natürlich ist die passende Optik einer Website auch eines der großen Ziele professionellen Webdesigns.
        Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich sowohl mit der visuellen als auch der
        strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre Erfahrung auf
        unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience (UX)
        und Usability. Unter User Experience versteht man alle Eindrücke und Erlebnisse, die der Websitebesucher auf
        einer Website erfährt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die
        Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder
        Produkte klar strukturiert sind und Sie leicht und schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche
        Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern. Unter
        die Kategorie „Webdesign“ fallen in dieser Datenschutzerklärung also alle Dienste, die unsere Website
        gestalterisch verbessern. Das können beispielsweise Schriftarten, diverse Plugins oder andere eingebundene
        Webdesign-Funktionen sein.</p>
    <h3 class="adsimple-312054461">Warum verwenden wir Webdesign-Tools?</h3>
    <p>Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von der Struktur, der Funktionalität und der
        visuellen Wahrnehmung der Website ab. Daher wurde auch für uns ein gutes und professionelles Webdesign immer
        wichtiger. Wir arbeiten ständig an der Verbesserung unserer Website und sehen dies auch als erweiterte
        Dienstleistung für Sie als Websitebesucher. Weiters hat eine schöne und funktionierende Website auch
        wirtschaftliche Vorteile für uns. Schließlich werden Sie uns nur besuchen und unsere Angebote in Anspruch
        nehmen, wenn Sie sich rundum wohl fühlen.</p>
    <h3 class="adsimple-312054461">Welche Daten werden durch Webdesign-Tools gespeichert?</h3>
    <p>Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren Seiten eingebunden sein, die auch Daten
        verarbeiten können. Um welche Daten es sich genau handelt, hängt natürlich stark von den verwendeten Tools ab.
        Weiter unter sehen Sie genau, welche Tools wir für unsere Website verwenden. Wir empfehlen Ihnen für nähere
        Informationen über die Datenverarbeitung auch die jeweilige Datenschutzerklärung der verwendeten Tools
        durchzulesen. Meistens erfahren Sie dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und wie
        lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch
        Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und
        Name des Browsers automatisch an die Google-Server übertragen.</p>
    <h3 class="adsimple-312054461">Dauer der Datenverarbeitung</h3>
    <p>Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von den eingesetzten Webdesign-Elementen ab.
        Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein paar
        Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu empfehlen wir Ihnen einerseits unseren
        allgemeinen Textabschnitt über Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort erfahren
        Sie in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden.
        Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website verbessert
        werden. Grundsätzlich werden Daten immer nur so lange aufbewahrt, wie es für die Bereitstellung des Dienstes
        nötig ist. Bei gesetzlichen Vorschreibungen können Daten auch länger gespeichert werden.</p>
    <h3 class="adsimple-312054461">Widerspruchsrecht</h3>
    <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw.
        Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere
        Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die
        Cookies verwalten, deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es
        allerdings auch Daten, die nicht ganz so einfach gelöscht werden können. Das ist dann der Fall, wenn Daten
        direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) übermittelt
        werden. Wenden Sie sich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie
        den Support unter <a class="adsimple-312054461"
            href="https://support.google.com/?hl=de">https://support.google.com/?hl=de</a>.</p>
    <h3 class="adsimple-312054461">Rechtsgrundlage</h3>
    <p>Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden dürfen, ist die Rechtsgrundlage der
        entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO
        (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch
        Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das Webdesign
        auf unserer Website zu verbessern. Schließlich können wir Ihnen nur dann ein schönes und professionelles
        Webangebot liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte
        Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Das
        wollen wir hier auf jeden Fall nochmals betonen.</p>
    <p>Informationen zu speziellen Webdesign-Tools erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden
        Abschnitten.</p>
    <p>Alle Texte sind urheberrechtlich geschützt.</p>
    <p style="margin-top:15px">Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/"
            title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz Generator</a> von AdSimple</p>
</app-wrapper>