<app-spacer></app-spacer>
<app-wrapper>
    <h1>{{'ueber_uns.title' | translate}}</h1>
    <p style="text-align: center">
        <!-- Leider können wir nichts über uns sagen, da wir noch keine Informationen über uns haben. -->
    </p>
    <br>
    <div class="team">
        <div class="item">
            <div class="image-box">
                <img src="assets/images/team/patrick.webp">
            </div>

            <div class="image-box hair">
                <img src="assets/images/team/patrick.webp">
            </div>
            <div class="text">
                <b class="name">Patrick Hörtreiter</b>
                <p>Software Engineer</p>
            </div>
        </div>
        <div class="item">
            <div class="image-box">
                <img src="assets/images/team/niklas.webp">
            </div>

            <div class="image-box hair">
                <img src="assets/images/team/niklas.webp">
            </div>
            <div class="text">
                <b class="name">Niklas Rose</b>
                <p>Data Analyst</p>
            </div>
        </div>
        <div class="item">
            <div class="image-box">
                <img src="assets/images/team/manuel.webp">
            </div>

            <div class="image-box hair">
                <img src="assets/images/team/manuel.webp">
            </div>
            <div class="text">
                <b class="name">Manuel Wolf</b>
                <p>Software Engineer</p>
            </div>
        </div>
    </div>
    
    <div class="office">
        <h1>{{'ueber_uns.office' | translate}}</h1>
        <div class="box">
            <img src="assets/images/office/office1.jpg" alt="">
        </div>
        <div class="box">
            <img src="assets/images/office/office2.jpg" alt="">
        </div>
        
    </div>
    <!-- <div class="office">
        <h1>Office</h1>
        <div class="flex">
            <div class="image-box">
                <img src="assets/images/team/office.webp" alt="">
                <div class="text"><b> Our Office</b>
                    <a href="https://goo.gl/maps/RpqUaMnXF5yJH1Xj8" target="_blank">
                        <p>
                            Amazing office in the heart of Munich. <i class="bi bi-geo-alt-fill"></i>
                        </p>
                    </a>
                </div>
            </div>
            <div class="article">
                Text
            </div>
        </div>
        <div class="flex flex-end">
            <div class="article">
                Text
            </div>
            <div class="image-box">
                <img src="assets/images/team/office_2.webp" alt="">
                <div class="text"></div>
            </div>
        </div>
    </div> -->
</app-wrapper>