import { Component, Input, OnInit } from '@angular/core';
import { techStackItem } from 'src/app/models/techstack-item';

@Component({
  selector: 'app-tech-grid',
  templateUrl: './tech-grid.component.html',
  styleUrls: ['./tech-grid.component.scss']
})
export class TechGridComponent implements OnInit {

  @Input() techstack: techStackItem[];

  constructor() { }

  ngOnInit(): void {
  }

}