<header class="header" showHeader>
  <div class="wrap">
    <a routerLink="/" class="logo"><img src="../../assets/logo.svg" alt=""></a>
    <input [(ngModel)]="checked" class="menu-btn" type="checkbox" id="menu-btn" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
    <ul class="menu">
      <li class="item">
        <a class="mainlink" routerLink="/prozessoptimierung" (click)="close()">{{'ui.header.process_optimization' | translate}}</a>
      </li>
      <li class="item">
        <a class="mainlink" routerLink="/individualsoftware" (click)="close()">{{'ui.header.custom_software' | translate}}</a>
      </li>

      <li class="item">
        <a class="mainlink" routerLink="/newbeevision" (click)="close()">{{'ui.header.itt' | translate}}</a>
      </li>
      <li class="item">
        <a class="mainlink" routerLink="/kontakt" (click)="close()">{{'ui.header.contact' | translate}}</a>
      </li>
      <!-- <li class="item"><a class="mainlink" routerLink="/" (click)="navigateExperiences()">{{'ui.header.more' |
          translate}}</a>
        <div class="submenu">
          <ul>            
            <li><a routerLink="/webdevelopment" class="dropdown-item">{{'home.experience.webdev.title' | translate}}</a>
            </li>
            <li><a routerLink="/it-service" class="dropdown-item">{{'home.experience.itservice.title' | translate}}</a>
            </li>
            <li><a routerLink="/mobiledevelopment" class="dropdown-item">{{'home.experience.apps.title' |
                translate}}</a></li>
            <li><a routerLink="/individualsoftware" class="dropdown-item">{{'home.experience.individual_software.title'
                | translate}}</a></li>
            <li><a routerLink="/dataanalytics" class="dropdown-item">{{'home.experience.business.title' |
                translate}}</a></li>
            <li><a routerLink="/animation" class="dropdown-item">{{'home.experience.animation.title' | translate}}</a>
            </li>
          </ul>
        </div>
      </li> -->
      <li class="item btn-item flex">
        <!-- <div class="btnlink" >
          <a id="dashboard" href="https://dashboard.newbeemountain.com/" target="_blank"
            class="btn" (click)="close()">{{'ui.header.customer-dashboard' | translate}}</a>
        </div> -->
        <img class="lang" src="assets/images/icons/lang.png" alt="" (click)="switchLang()" (click)="close()">
        <div class="darkmode"><app-dark-mode-toggle (click)="close()"></app-dark-mode-toggle></div>
      </li>
      <!-- <li class="item"><a class="mainlink" routerLink="/impressum" (click)="close()">{{'ui.header.imprint' | translate}}</a></li> -->


    </ul>
  </div>
</header>