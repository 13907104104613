<app-spacer></app-spacer>
<div class="background">
<app-wrapper>
    <div class="impressum">
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Newbeemountain GmbH<br />
            Tölzer Str. 1<br />
            82031 Grünwald</p>

        <p>Handelsregister: 264415<br />
            Registergericht: Amtsgericht M&uuml;nchen</p>

        <p><strong>Vertreten durch:</strong><br />
            Patrick H&ouml;rtreiter, Niklas Rose, Manuel Wolf</p>
        <br>
        <h2>Kontakt</h2>
        <p>
            Telefon: 0152 33700524<br />
            E-Mail: info@newbeemountain.com </p>
        <br>
        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
            DE342009294</p>
        <br>
        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                href="https://ec.europa.eu/consumers/odr/" target="_blank"
                rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden
            Sie oben im Impressum.</p>
        <br>
        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.</p>
        <br>
        <h2>Haftung</h2>
        <p>Der Internetauftritt wurde sorgfältig von der Newbeemountain GmbH erstellt. Eine Gewährleistung für die
            Aktualität,
            Vollständigkeit und Richtigkeit der hier rechtlich unverbindlich zur Verfügung gestellten Informationen kann
            jedoch nicht übernommen werden. Dies gilt insbesondere für Verbindungen („Links“), auf die die
            Newbeemountain GmbH
            direkt oder indirekt verweist, da die Newbeemountain GmbH auf den Inhalt einer Seite, die mit einem solchen
            Link
            erreicht wird, keinen Einfluss hat. Die Newbeemountain GmbH kann daher nicht für Schäden haftbar gemacht
            werden,
            die im Zusammenhang mit der Verwendung dieser Inhalte stehen, es sei denn, es liegt vorsätzliches oder grob
            fahrlässiges Handeln von der Newbeemountain GmbH, ihrer Vertreter oder ihrer Erfüllungsgehilfen vor.
            Insbesondere
            kann nicht für mittelbare Schäden oder Folgeschäden, insbesondere Schäden wegen Datenlöschung oder
            Datenzerstörung, gehaftet werden. Soweit durch Verlinkung auf andere Server Informationen zugänglich gemacht
            werden, weist die Newbeemountain GmbH darauf hin, dass eine Inhaltskontrolle nicht stattfindet. Keinesfalls
            wird
            für fremde Inhalte, zu denen die Newbeemountain GmbH lediglich den Zugang vermittelt, gehaftet (§ 9 Abs. 1
            TDG).
            Auch eine automatische kurzzeitige Zwischenspeicherung fremder Informationen auf den Servern der
            Newbeemountain
            GmbH gilt gemäss § 11 Abs. 2 TDG als Zugangsvermittlung.</p>
    </div>
    <br>
</app-wrapper>
</div>