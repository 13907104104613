import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss', '../experiences.scss']
})
export class AnimationComponent implements OnInit {

  experienceBtn = false;

  web_dev: AnimationOptions = {
    path: '../../assets/lottie/icons/website/data.json',
  };
  animation2D: AnimationOptions = {
    path: '../../assets/lottie/animation2D/data.json',
  };
  animation3D: AnimationOptions = {
    path: '../../assets/lottie/animation3D/data.json',
  };
  constructor() { }

  ngOnInit(): void {
  }

  experience() {
    this.experienceBtn = !this.experienceBtn
  }

}
