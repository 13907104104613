import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as TWEEN from '@tweenjs/tween.js';

@Component({
  selector: 'app-three',
  templateUrl: './three.component.html',
  styleUrls: ['./three.component.scss']
})
export class ThreeComponent implements OnInit {
  
  //Canvas
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }
  //Debug
  // gui = new dat.GUI();

  //Scene
  scene = new THREE.Scene();
  //Loaders
  dracoLoader = new DRACOLoader();
  gltfLoader = new GLTFLoader();
  textureLoader = new THREE.TextureLoader()

  //Sizes
  sizes: any = { width: 0, height: 0 };
  //Camera
  camera: any;
  //Controls
  controls: any;
  //Renderer
  renderer: any;
  //Raycaster
  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();
  currentIntersect: any = null;
  raycasterItems: any = []
  //configurator
  configurator = new THREE.Group()

  state = 'start'

  item: any;
  itemMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0.5, color: 0xffffff })


  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
    this.buildCanvas()
    // this.createModel()
    this.createItem()
    // this.createPlane()
    this.createOffice()
    // this.createDrone()
  }
  addMouse() {
    window.addEventListener('mousemove', (event) => {
      this.mouse.x = event.clientX / this.sizes.width * 2 - 1;
      this.mouse.y = -(event.clientY / this.sizes.height * 2 - 1);
    })




    window.addEventListener('click', () => {
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.raycasterItems)

      if (intersects.length > 0) {
        this.currentIntersect = intersects[0];

      } else {
        this.currentIntersect = null;
      }


      if (this.currentIntersect) {
        const obj = this.currentIntersect.object;
        console.log(obj.name)
        for (let i = 0; i < 4; i++) {
          this.raycasterItems[i].position.y = 1.8513456583023071
          if (obj.name === this.raycasterItems[i].name) {
            obj.position.y = 1.84;
          }
        }
        switch (obj.name) {
          case 'Red':
            this.itemMaterial.color.setHex(0xaa0000);
            break;
          case 'Blue':
            this.itemMaterial.color.setHex(0x0000aa);
            break;
          case 'Green':
            this.itemMaterial.color.setHex(0x00aa00);
            break;
          case 'Yellow':
            this.itemMaterial.color.setHex(0xaaaa00);
            break;
          case 'Plattform':
            this.itemMaterial.color.setHex(0xffffff)
            if (this.state === 'orbit') {
              this.configuratorView()
            }
            break;
          case 'Curve':
            if (this.state === 'orbit') {
              this.startView()
            }
            break;
          case 'Regalbrett':
            if (this.state === 'orbit') {
              this.skillsView()
            }
            break;
          case 'Body':
            this.animateDrone()
            break;
        }
      }
    })

  }

  startView() {
    this.state = 'toOrbit'
    this.controls.enabled = false;
    const tween1 = new TWEEN.Tween({ x: this.camera.position.x, y: this.camera.position.y, z: this.camera.position.z, xr: this.camera.rotation.x, yr: this.camera.rotation.y, zr: this.camera.rotation.z })
      .to({ x: 1.7, y: 1.54, z: -2.72, xr: 0, yr: 0, zr: 0 }, 5000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onUpdate((obj: { x: number, y: number, z: number, xr: number, yr: number, zr: number }) => {
        this.camera.position.set(obj.x, obj.y, obj.z)
        this.camera.rotation.set(obj.xr, obj.yr, obj.zr)
      })
      .onComplete(() => {
        this.state = 'start'

      })
    tween1.start()
  }

  skillsView() {
    this.state = 'toOrbit'
    this.controls.enabled = false;
    const tween1 = new TWEEN.Tween({ x: this.camera.position.x, y: this.camera.position.y, z: this.camera.position.z, xr: this.camera.rotation.x, yr: this.camera.rotation.y, zr: this.camera.rotation.z })
      .to({ x: -2.564, y: 0, z: -4, xr: -1.414, yr: -1.181, zr: -1.402 }, 5000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onUpdate((obj: { x: number, y: number, z: number, xr: number, yr: number, zr: number }) => {
        this.camera.position.set(obj.x, obj.y, obj.z)
        this.camera.rotation.set(obj.xr, obj.yr, obj.zr)
      })
      .onComplete(() => {
        this.state = 'skills'

      })
    tween1.start()
  }

  configuratorView() {
    this.controls.enabled = false
    this.state = 'toOrbit'
    const tween1 = new TWEEN.Tween(
      { x: this.camera.position.x, y: this.camera.position.y, z: this.camera.position.z, xr: this.camera.rotation.x, yr: this.camera.rotation.y, zr: this.camera.rotation.z }
    ).to({ x: 0.123, y: 1.071, z: -1.949, xr: -0.2375, yr: 0, zr: 0 }, 5000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onUpdate((obj: { x: number, y: number, z: number, xr: number, yr: number, zr: number }) => {
        this.camera.position.set(obj.x, obj.y, obj.z)
        this.camera.rotation.set(obj.xr, obj.yr, obj.zr)
      }).onComplete(() => this.state = 'configurator')
    tween1.start()
  }

  toOrbit() {
    this.state = 'toOrbit'
    const tween1 = new TWEEN.Tween({ x: this.camera.position.x, y: this.camera.position.y, z: this.camera.position.z, xr: this.camera.rotation.x, yr: this.camera.rotation.y, zr: this.camera.rotation.z })
      .to({ x: -5, y: 2, z: 5, xr: -0.38, yr: -0.748, zr: -0.265 }, 5000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onUpdate((obj: { x: number, y: number, z: number, xr: number, yr: number, zr: number }) => {
        this.camera.position.set(obj.x, obj.y, obj.z)
        this.camera.rotation.set(obj.xr, obj.yr, obj.zr)
      })
      .onComplete(() => {
        this.state = 'orbit'
        this.controls.enabled = true;
      })
    tween1.start()
  }

  createItem() {
    this.dracoLoader.setDecoderPath('draco/');
    this.gltfLoader.setDRACOLoader(this.dracoLoader);
    this.gltfLoader.load(
      'assets/city/item.glb',
      (gltf) => {
        this.item = gltf.scene
        gltf.scene.scale.set(.4, .4, .4)
        gltf.scene.position.set(0.118, -0.1, -4.25)
        gltf.scene.traverse((child: any) => {
          child.material = this.itemMaterial
        })
        //Tween
        const tween = new TWEEN.Tween({ yr: 0 }).to({ yr: 2 * Math.PI }, 10000)
          .onUpdate((obj: { yr: number }) => {
            gltf.scene.rotation.y = obj.yr;
          }).onComplete(() => tween2.start())
        const tween2 = new TWEEN.Tween({ yr: 0 }).to({ yr: 2 * Math.PI }, 10000)
          .onUpdate((obj: { yr: number }) => {
            gltf.scene.rotation.y = obj.yr;
          }).onComplete(() => tween.start())
        tween.start()

        this.scene.add(gltf.scene)

      }
    )
  }

  drone = new THREE.Group()
  createDrone() {
    this.dracoLoader.setDecoderPath('draco/');
    this.gltfLoader.setDRACOLoader(this.dracoLoader);
    this.gltfLoader.load(
      'assets/city/office/drone.glb',
      (gltf) => {
        this.raycasterItems.push(gltf.scene)
        gltf.scene.scale.set(0.14, 0.14, 0.14)
        // const light = new THREE.AmbientLight(0xffffff); // soft white light
        const pointLight = new THREE.PointLight(0xffffff, 1, 100)
        pointLight.position.set(0, 1, 1)
        let mixer = new THREE.AnimationMixer(gltf.scene)
        var action = mixer.clipAction(gltf.animations[0]);
        action.play();
        this.drone.add(gltf.scene, pointLight)
        this.drone.position.set(2.1, -0.18, -4.17)
        const sphereSize = 1;
        this.scene.add(this.drone);

      }
    )
    // this.gui.add(this.drone.position, 'x', -10, 10, 0.1);
    // this.gui.add(this.drone.position, 'z', -10, 10, 0.1);
    // this.gui.add(this.drone.rotation, 'y', -Math.PI, Math.PI, 0.05);
  }
  animateDrone() {
    console.log(this.drone.position)
    const tween = new TWEEN.Tween({
      x: this.drone.position.x,
      y: this.drone.position.y,
      z: this.drone.position.z,
      yr: this.drone.rotation.y
    })
      .to({
        x: 4.5, y: 0, z: -2.2, yr: 1.7
      }, 2500)
      .easing(TWEEN.Easing.Quadratic.In)
      .onUpdate((obj: { x: number, y: number, z: number, yr: number }) => {
        this.drone.position.set(obj.x, obj.y, obj.z)
        this.drone.rotation.y = obj.yr;
        console.log(this.drone.position.x)
      }).onComplete(() => {
        const tween2 = new TWEEN.Tween({
          x: this.drone.position.x,
          y: this.drone.position.y,
          z: this.drone.position.z,
          yr: this.drone.rotation.y
        })
          .to({
            x: 4.8, y: 0, z: 7, yr: -1.7
          }, 7000)          
          .onUpdate((obj: { x: number, y: number, z: number, yr: number }) => {
            this.drone.position.set(obj.x, obj.y, obj.z)
            this.drone.rotation.y = obj.yr;
            console.log(this.drone.position.x)
          }).onComplete(() => {
            const tween3 = new TWEEN.Tween({
              x: this.drone.position.x,
              y: this.drone.position.y,
              z: this.drone.position.z,
              yr: this.drone.rotation.y
            })
              .to({
                x: -1, y: -0.18, z: -2, yr: 0
              }, 3000)              
              .onUpdate((obj: { x: number, y: number, z: number, yr: number }) => {
                this.drone.position.set(obj.x, obj.y, obj.z)
                this.drone.rotation.y = obj.yr;
                console.log(this.drone.position.x)
              }).onComplete(() => {
                const tween4 = new TWEEN.Tween({
                  x: this.drone.position.x,
                  y: this.drone.position.y,
                  z: this.drone.position.z,
                  yr: this.drone.rotation.y
                })
                  .to({
                    x: 2.1, y: -0.18, z: -4.17, yr: 0
                  }, 3000)
                  .easing(TWEEN.Easing.Quadratic.Out)
                  .onUpdate((obj: { x: number, y: number, z: number, yr: number }) => {
                    this.drone.position.set(obj.x, obj.y, obj.z)
                    this.drone.rotation.y = obj.yr;
                    console.log(this.drone.position.x)
                  }).onComplete(() => console.log('Animation finished'))
                tween4.start()
              })
            tween3.start()
          })
        tween2.start()
      })
    tween.start()



  }
  // createPlane() {
  //   const texture = this.textureLoader.load('assets/city/references/image.png')
  //   let geometry = new THREE.PlaneGeometry(16, 9); // ensure correct aspect ratio
  //   let material = new THREE.MeshBasicMaterial({ map: texture });
  //   let mesh = new THREE.Mesh(geometry, material);
  //   mesh.position.y = 4.25
  //   mesh.position.z = .8
  //   mesh.scale.set(.5, .5, .5)
  //   this.scene.add(mesh);
  // }

  createOffice() {
    this.camera.rotation.set(0, 0, 0)
    this.controls.enabled = false;
    const bakedTexture = this.textureLoader.load('assets/city/office/office.jpg')
    bakedTexture.flipY = false
    bakedTexture.encoding = THREE.sRGBEncoding
    const lightMaterial = new THREE.MeshBasicMaterial({ color: 0xfffff5 })
    const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })
    this.gltfLoader.load(
      'assets/city/office/office.glb',
      (gltf) => {
        gltf.scene.traverse((child: any) => {
          child.material = bakedMaterial
        })
        let letters: any = gltf.scene.children.find((child) => child.name === 'Curve')
        let lamp: any = gltf.scene.children.find((child) => child.name === 'Lamp')
        this.raycasterItems.push(gltf.scene.children.find((child) => child.name === 'Red'))
        this.raycasterItems.push(gltf.scene.children.find((child) => child.name === 'Blue'))
        this.raycasterItems.push(gltf.scene.children.find((child) => child.name === 'Yellow'))
        this.raycasterItems.push(gltf.scene.children.find((child) => child.name === 'Green'))
        this.raycasterItems.push(gltf.scene.children.find((child) => child.name === 'Plattform'))
        this.raycasterItems.push(gltf.scene.children.find((child) => child.name === 'Curve'))
        this.raycasterItems.push(gltf.scene.children.find((child) => child.name === 'Regalbrett'))

        letters.material = lightMaterial
        lamp.material = lightMaterial
        gltf.scene.rotation.y = -Math.PI / 2
        gltf.scene.position.y = -2
        this.scene.add(gltf.scene)
      }
    )
  }


  buildCanvas() {
    this.sizes.height = this.canvas.parentElement?.clientHeight;
    this.sizes.width = this.canvas.parentElement?.clientWidth;
    this.camera = new THREE.PerspectiveCamera(50, this.sizes.width / this.sizes.height, 0.1, 100);
    //x: 1.7, y: 1.54, z: -2.72, xr: 0, yr: 0, zr: 0 
    this.camera.position.set(1.7, 1.54, -2.72);
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true
    });
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setClearColor('28323e');

    window.addEventListener('resize', () => {
      this.sizes.height = this.canvas.parentElement?.clientHeight;
      this.sizes.width = this.canvas.parentElement?.clientWidth;
      this.camera.aspect = this.sizes.width / this.sizes.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.sizes.width, this.sizes.height);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    })
    this.scene.background = new THREE.Color(0x191919)
    this.scene.add(this.camera)
    this.addControls();
    this.addMouse();

    const clock = new THREE.Clock();
    const tick = () => {
      const elapsedTime = clock.getElapsedTime();
      this.renderer.render(this.scene, this.camera);
      //Raycaster

      //Tween
      TWEEN.update()
      //End
      window.requestAnimationFrame(tick);
    }
    tick()
  }

  addControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = .05;
    this.controls.update();
    this.controls.minPolarAngle = .3;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.minAzimuthAngle = - Math.PI / 2
    this.controls.maxAzimuthAngle = Math.PI / 2 - 1.4;
    this.controls.enablePan = false;
    this.controls.minDistance = .2;
    this.controls.maxDistance = 10;
  }


}
