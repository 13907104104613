<div class="color" id="header_process">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div id="header_title">
                <h1>{{'process_optimization.title' | translate}}</h1>
                <p>{{'process_optimization.description' | translate}}</p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>                
                
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <app-wrapper>
        <h1>{{'process_optimization.advantages.title' | translate}}</h1>
        <div class="advantages">
            <div *ngFor="let advantage of 'process_optimization.advantages.array'|translate ">
                {{advantage}}
            </div>
            
        </div>
        <h1>{{'process_optimization.way_of_working.title' | translate}}</h1>
        <div class="grid">
            <div class="item"
                *ngFor="let step of 'process_optimization.way_of_working.steps' | translate; let index = index">
                <div class="image-path">
                    <ng-lottie [options]="animations[index]"></ng-lottie>
                </div>
                <h3>{{step.title}}</h3>
                <br>
                <ul>
                    <li *ngFor="let bulletpoint of step.bullet_points">{{bulletpoint}}</li>
                </ul>
            </div>
        </div>
        <div class="bpmn">
            <h1>{{'process_optimization.visualization.title' | translate}}</h1>
            <p>{{'process_optimization.visualization.text' | translate}}</p>
            <img class="bpmnDiagram" src="assets/images/process-optimization/bpmn-diagram.png" alt="">
        </div>
    </app-wrapper>
</div>