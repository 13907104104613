import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.scss']
})
export class ContactPersonComponent implements OnInit {

  @Input() name: string;
  persons = [
    {
      name: 'Manuel Wolf',
      mail: 'manuel.wolf@newbeemountain.com',
      tel: '+491632496937',
      imgPath: '../../assets/images/team/manuel.webp'
    },
    {
      name: 'Niklas Rose',
      mail: 'niklas.rose@newbeemountain.com',
      tel: '+4915233700524',
      imgPath: '../../assets/images/team/niklas.webp'
    },
    {
      name: 'Patrick Hörtreiter',
      mail: 'patrick.hoertreiter@newbeemountain.com',
      tel: 0,
      imgPath: '../../assets/images/team/patrick.webp'
    },
  ];
  current: any;


  constructor() { }

  ngOnInit(): void {
    this.current = this.persons.find(p => p.name == this.name);
  }

}
