import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { techStackItem } from 'src/app/models/techstack-item';

@Component({
  selector: 'app-individualsoftware-page',
  templateUrl: './individualsoftware-page.component.html',
  styleUrls: ['./individualsoftware-page.component.scss', '../experiences.scss']
})
export class IndividualsoftwarePageComponent implements OnInit {

  techstack: techStackItem[] = [
    {
      logopath: '../../assets/images/tech-logos/tslogo.webp',
      name: 'Typescript',
      url: 'https://www.typescriptlang.org/'
    },
    {
      logopath: '../../assets/images/tech-logos/jslogo.webp',
      name: 'Javascript',
      url: 'https://www.javascript.com/'
    },
    {
      logopath: '../../assets/images/tech-logos/nodelogo.webp',
      name: 'Node.js',
      url: 'https://nodejs.org/en/'
    },
    {
      logopath: '../../assets/images/tech-logos/csharplogo.webp',
      name: 'C#',
      url: 'https://docs.microsoft.com/de-de/dotnet/csharp/tour-of-csharp/'
    },
    {
      logopath: '../../assets/images/tech-logos/aspnetlogo.webp',
      name: 'ASP.NET',
      url: 'https://dotnet.microsoft.com/apps/aspnet'
    },
    {
      logopath: '../../assets/images/tech-logos/angularlogo.webp',
      name: 'Angular',
      url: 'https://angular.io/'
    },
    {
      logopath: '../../assets/images/tech-logos/gitlogo.webp',
      name: 'git',
      url: 'https://git-scm.com/'
    },
    {
      logopath: '../../assets/images/tech-logos/dockerlogo.webp',
      name: 'Docker',
      url: 'https://www.docker.com/'
    },
    {
      logopath: '../../assets/images/tech-logos/flutterlogo.webp',
      name: 'Flutter',
      url: 'https://flutter.dev/'
    },
    {
      logopath: '../../assets/images/tech-logos/mongodblogo.webp',
      name: 'MongoDb',
      url: 'https://www.mongodb.com/de-de'
    },
    {
      logopath: '../../assets/images/tech-logos/mysqllogo.webp',
      name: 'MySql',
      url: 'https://www.mysql.com/de/'
    },
    {
      logopath: '../../assets/images/tech-logos/unitylogo.webp',
      name: 'Unity',
      url: 'https://unity.com/de'
    },

  ]

  your_app: AnimationOptions = {
    path: '../../assets/lottie/hero/individual_software/data.json',
  };
 
  private animationItem: AnimationItem;

  constructor(private titleService: Title) {
    this.titleService.setTitle("Individualsoftware | NBM");
  }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  onAppear() {
    this.animationCreated(this.animationItem)
  }

}
