import { Component, OnInit, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslatePipe } from '@ngx-translate/core';

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: 'app-mobile-page',
  templateUrl: './mobile-page.component.html',
  styleUrls: ['./mobile-page.component.scss', '../experiences.scss']
})
export class MobilePageComponent implements OnInit {

  your_app: AnimationOptions = {
    path: '../../assets/lottie/your_app/data.json',
  };
  screen_size: AnimationOptions = {
    path: '../../assets/lottie/screen_size/data.json',
  };

  launch: AnimationOptions = {
    path: '../../assets/lottie/icons/launch/data.json',
  };
  requirements: AnimationOptions = {
    path: '../../assets/lottie/icons/requirements/data.json',
  };
  code: AnimationOptions = {
    path: '../../assets/lottie/icons/code/data.json',
  };
  uiux: AnimationOptions = {
    path: '../../assets/lottie/icons/uiux/data.json',
  };
  bug: AnimationOptions = {
    path: '../../assets/lottie/icons/bug/data.json',
  };




  items = [
    {
      headline: 'mobile.section_2.card_1.title',
      description: 'mobile.section_2.card_1.text',
      imagePath: this.requirements
    },
    {
      headline: 'mobile.section_2.card_2.title',
      description: 'mobile.section_2.card_2.text',
      imagePath: this.uiux
    },
    {
      headline: 'mobile.section_2.card_3.title',
      description: 'mobile.section_2.card_3.text',
      imagePath: this.code
    },
    {
      headline: 'mobile.section_2.card_4.title',
      description: 'mobile.section_2.card_4.text',
      imagePath: this.bug
    },
    {
      headline: 'mobile.section_2.card_5.title',
      description: 'mobile.section_2.card_5.text',
      imagePath: this.launch
    },
  ];

  private screenSizeItem: AnimationItem;

  constructor(private titleService: Title) {
    this.titleService.setTitle("Mobile Entwicklung | NBM");
  }

  ngOnInit(): void {

  }
  screenSizeCreated(screenSizeItem: AnimationItem): void {

    this.screenSizeItem = screenSizeItem;
    // this.screenSizeItem.stop();


  }

  startScreenSize() {
    this.screenSizeCreated(this.screenSizeItem);

    // this.screenSizeItem.play();

  }

  stopScreenSize() {
    if (Math.round(this.screenSizeItem.currentFrame) === 96) {

      this.screenSizeItem.pause();

    }

  }
}
