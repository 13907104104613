import { animate, AnimationBuilder, style } from '@angular/animations';
import { Directive, ElementRef, EventEmitter, HostBinding, Output } from '@angular/core';
import { element } from 'protractor';
import { Subscription, fromEvent } from 'rxjs';

@Directive({
  selector: '[showHeader]'
})
export class HeaderDirective {
  @Output() headerScroll: EventEmitter<void>;
  subscriptionScroll: Subscription;

  //how many pixels to scroll until header appears
  SCROLL_RANGE = 1

  scrollPos: number;
  rangeScrolledTop: number = 0
  rangeScrolledBottom: number = 0
  isShown: boolean = true
  @HostBinding('style.top')
  top: string = '0px';

  constructor(private element: ElementRef, private builder: AnimationBuilder) {
    this.headerScroll = new EventEmitter<void>();
  }

  subscribe() {
    this.subscriptionScroll = fromEvent(window, 'scroll')
      .subscribe(() => {
        this.saveScrollPos();
        this.checkToShowHeader();
      });
  }

  saveScrollPos() {
    if (this.scrollPos >= window.scrollY) {
      this.rangeScrolledBottom = 0
      this.rangeScrolledTop++
    } else {
      this.rangeScrolledTop = 0
      this.rangeScrolledBottom++
    }
    this.scrollPos = window.scrollY;
  }

  checkToShowHeader() {
    let metadata
    //show
    if (this.scrollPos === 0 || this.rangeScrolledTop >= this.SCROLL_RANGE) {
      if (!this.isShown) {
        this.show()
      }

    }
    //hide
    else if (this.rangeScrolledBottom >= this.SCROLL_RANGE) {
      if (this.isShown) {
        this.hide()
      }
    }
  }

  unsubscribe() {
    if (this.subscriptionScroll) {
      this.subscriptionScroll.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.saveScrollPos();
    this.checkToShowHeader();
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

  private show() {
    this.isShown = true
    this.top = '0px'
  }

  private hide() {
    this.isShown = false
    this.top = `-${this.element.nativeElement.offsetHeight}px`
  }
}
