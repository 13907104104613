import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-itt',
  templateUrl: './itt.component.html',
  styleUrls: ['./itt.component.scss', '../experiences.scss']
})
export class IttComponent implements OnInit {

  hero: AnimationOptions = {
    path: '../../assets/lottie/hero/itt/data.json'
  }

  constructor(private titleService: Title) { 
    this.titleService.setTitle("NewBeeVision | NBM");
  }

  ngOnInit(): void {
    console.log('log')
  }

}
