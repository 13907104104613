<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>
                <h1>Data Analytics</h1>
                <p>Ein wichtiger Baustein der Digitalisierung Ihres Unternehmens ist die systematische und strategische
                    Datenanalyse.
                    Wir begleiten Sie vom Aufsetzen von Data Lakes hin bis zur Auswertung und Nutzung Ihrer
                    Unternehmensdaten.
                </p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>
                <ng-lottie (appear)="onAppear()" [options]="your_app" (animationCreated)="animationCreated($event)">
                </ng-lottie>
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <div class=" section section-1">
        <app-wrapper>
            <h1 scroll-animation [fadeDirection]="'up'">Datenarchitektur</h1>
            <div class="flex">
                <div class="img-wrapper" scroll-animation [fadeDirection]="'up'">
                    <img src="./assets/images/dataanalytics/Folie4.webp" alt="">
                </div>
                <div class="side">
                    <table>
                        <tr *ngFor="let item of datenarchitekurItems" scroll-animation [fadeDirection]="'up'">
                            <td class="list-symbol">
                                <div class="symbol-wrapper"></div>
                            </td>
                            <td>
                                <h3>{{item.title}}</h3>
                                <p>{{item.description}}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <p scroll-animation [fadeDirection]="'up'">
                Die wichtige Grundlage der Datenanalyse ist eine solide Dateninfrastruktur.
                Eine gute Dateninfrastruktur speichert die durch Ihre Geschäftsprozesse und Unternehmenstätigkeiten
                erzeugten Rohdaten,
                strukturiert diese anschließend sinnvoll, um anhand von Datenanalysen nützliche Erkenntnisse über Ihre
                eigene Geschäftstätigkeiten zu gewinnen.

            </p>
            <div class="flex">
                <div class="side">
                    <table>
                        <tr *ngFor="let item of dateninfrastrukurItems" scroll-animation [fadeDirection]="'up'">
                            <td class="list-symbol">
                                <div class="symbol-wrapper"></div>
                            </td>
                            <td>
                                <h3>{{item.title}}</h3>
                                <p>{{item.description}}</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="img-wrapper" scroll-animation [fadeDirection]="'up'">
                    <img src="../../assets/images/dataanalytics/Folie6.webp" alt="">
                </div>
            </div>
            <p scroll-animation [fadeDirection]="'right'">
                Ein wichtiger Teilprozess ist dabei die Datenkategorisierung, um die Daten von Beginn an auf ihre
                betriebswirtschaftliche Relevanz zu prüfen und anhand der Kosten-Nutzen-Rechnung priorisierte
                Data-Investmententscheidungen zu treffen.
            </p>
            <div class="flex">
                
                <div class="img-wrapper" scroll-animation [fadeDirection]="'up'">
                    <img src="../../assets/images/dataanalytics/Folie8.webp" alt="">
                </div>
                <div class="side">
                    <table>
                        <tr *ngFor="let item of categoryItems " scroll-animation [fadeDirection]="'up'">
                            <td class="list-symbol">
                                <div class="symbol-wrapper"></div>
                            </td>
                            <td>
                                <h3>{{item.title}}</h3>
                                <p>{{item.description}}</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </app-wrapper>
    </div>

    <div class="section section-2">
        <app-wrapper>
            <h1 scroll-animation [fadeDirection]="'up'">Anwendungsarchitektur</h1>
            <p scroll-animation [fadeDirection]="'up'">
                Eine große Herausforderung während der Transformation als auch Optimierung Ihrer Digitalisierungsstrategie ist die Einführung und nachhaltige Pflege einer klaren Anwendungsarchitektur.</p>
            <div class="img-wrapper" scroll-animation [fadeDirection]="'up'">
                <img src="../../assets/images/dataanalytics/Folie11.webp" alt="">
            </div>
        </app-wrapper>
    </div>

    <div class="section section-3">
        <app-wrapper>
            <h1 scroll-animation [fadeDirection]="'up'">Individuelle Datenverbindungen</h1>
            <p scroll-animation [fadeDirection]="'up'">
                Wir legen individuelle, digitale “Datenleitungen” zwischen bestehenden Softwareanwendungen, um Ihre
                Arbeitsprozesse zu beschleunigen oder zu optimieren.
            </p>
            <div class="img-wrapper" scroll-animation [fadeDirection]="'up'">
                <img src="../../assets/images/dataanalytics/Folie12.webp" alt="">
            </div>
        </app-wrapper>
    </div>
    <div class="section section-4">
        <app-wrapper>
            <h1 scroll-animation [fadeDirection]="'up'">Wir strukturieren und analysieren Ihre Daten mit den
                branchenüblichen Softwarelösungen, Datenbanken und
                Programmiersprachen.</h1>
            <div class="flex">
                <div class="flexitem" scroll-animation [fadeDirection]="'right'">
                    <p>
                        Egal ob Sie bereits ein Data Lake haben, ob Sie über eine Dateninfrastruktur weitgehend besitzen
                        oder bereits Ihre Daten von A bis Z unter Kontrolle haben. Wir stehen Ihnen gerne tatkräftig zur
                        Seite,
                        wenn Sie Rat und Tat benötigen.
                    </p>
                    <br>
                    <p>Wir unterstützen Sie in Fragen
                        bezüglich der Optimierung Ihrer Daten- und Anwendungsinfrastruktur, der Identifizierung der
                        eigenen
                        Daten und Datenströme, des
                        Aufsetzen eigener Data Lakes, des Data Managements und der Datenklassifizierung, der
                        Datenstrukturierung und
                        der automatisierten Datenanalyse und -visualisierung.
                    </p>
                    <br>
                    <p>
                        Daten sind das neue Gold der Unternehmen.
                        Aus und mit ihnen kann man neue Unternehmenswerte generieren, die Qualität von Entscheidungen
                        steigern,
                        Digitalisierungs- und Automatisierungspotenziale erkennen und neue Geschäftsmöglichkeiten
                        strategisch
                        nutzen.
                        Wir unterstützen Sie gerne dabei, noch mehr aus Ihren Daten herauszuholen und Ihr Unternehmen
                        noch
                        digitaler aufzustellen als es bereits ist.
                    </p>
                </div>
                <app-tech-grid class="flexitem" [techstack]="techstack" scroll-animation [fadeDirection]="'left'">
                </app-tech-grid>
            </div>
            <app-contact-person name="Niklas Rose"></app-contact-person>
        </app-wrapper>
    </div>    
</div>