import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-entwicklungsprozesse',
  templateUrl: './entwicklungsprozesse.component.html',
  styleUrls: ['./entwicklungsprozesse.component.scss']
})
export class EntwicklungsprozesseComponent implements OnInit {
  mobile: boolean = false
  isAnimation: boolean = false

  constructor() { }

  ngOnInit(): void {
    if (window.screen.width <= 766) { // 768px portrait
      this.mobile = true;
    }
  }

  startAnimation() {
    this.isAnimation = true
  }

}
