<h1 scroll-animation [fadeDirection]="'up'" id="about">About.</h1>
<div class="flex">
    <div class="logo" scroll-animation [fadeDirection]="'up'">
        <img src="../../assets/logo.svg" alt="">
    </div>
    <div class="text" scroll-animation [fadeDirection]="'up'" [delay]="250">
        <p align="justify" [innerHtml]="'home.about' | translate"></p>
        <br>
        <a class="btn" routerLink="ueber-uns">{{'ui.header.about' | translate}}</a>
    </div>
    
</div>