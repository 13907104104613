import { Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-it-service',
  templateUrl: './it-service-page.component.html',
  styleUrls: ['./it-service-page.component.scss', '../experiences.scss']
})
export class ItServicePageComponent implements OnInit {

  hero: AnimationOptions = {
    path: '../../assets/lottie/it_services/data.json',
  };
  cloud_wiggling: AnimationOptions = {
    path: '../../assets/lottie/icons/cloud_wiggling/data.json',
  };
  installation: AnimationOptions = {
    path: '../../assets/lottie/icons/installation/data.json',
  };
  berater: AnimationOptions = {
    path: '../../assets/lottie/icons/berater/data.json',
  };
  microsoft: AnimationOptions = {
    path: '../../assets/lottie/icons/microsoft/data.json',
  };
  server: AnimationOptions = {
    path: '../../assets/lottie/icons/server/data.json'
  }
  website: AnimationOptions = {
    path: '../../assets/lottie/icons/website/data.json'
  }

  private animationItem: AnimationItem;

  items = [
    {
      headline: "it_service.section_1.card_1.title",
      description: "it_service.section_1.card_1.text",
      imagePath: this.cloud_wiggling
    },
    {
      headline: "it_service.section_1.card_2.title",
      description: "it_service.section_1.card_2.text",
      imagePath: this.website
    },
    {
      headline: "it_service.section_1.card_3.title",
      description: "it_service.section_1.card_3.text",
      imagePath: this.berater
    },
    {
      headline: "it_service.section_1.card_4.title",
      description: "it_service.section_1.card_4.text",
      imagePath: this.installation
    },
    {
      headline: "it_service.section_1.card_5.title",
      description: "it_service.section_1.card_5.text",
      imagePath: this.microsoft
    },
    {
      headline: "it_service.section_1.card_6.title",
      description: "it_service.section_1.card_6.text",
      imagePath: this.server
    },

  ]

  faqs = [
    {
      question: "Wer ist der Coolste?",
      answer: "Mit unserer Hilfe können Sie sich erleichtert auf Ihre Hauptaufgaben konzentrieren und unbeschwert Ihrer täglichen Arbeit nachgehen.Als kompetenter IT-Dienstleister bieten wir Ihnen die Beratung, Beschaffung, Installation und den Support Ihrer IT-Infrastruktur komplett aus einer Hand an."
    },
    {
      question: "Wer ist der Coolste?",
      answer: "Das ist ja klar wie Kloßbrühe. Das bin ich. So war es, so ist es und so wird es immer sein. Meine Damen und Herren man riecht sich. Auf wiederhörnchen"
    },
    {
      question: "Wer ist der Coolste?",
      answer: "Das ist ja klar wie Kloßbrühe. Das bin ich. So war es, so ist es und so wird es immer sein. Meine Damen und Herren man riecht sich. Auf wiederhörnchen"
    },
    {
      question: "Wer ist der Coolste?",
      answer: "Das ist ja klar wie Kloßbrühe. Das bin ich. So war es, so ist es und so wird es immer sein. Meine Damen und Herren man riecht sich. Auf wiederhörnchen"
    },
    {
      question: "Wer ist der Coolste?",
      answer: "Das ist ja klar wie Kloßbrühe. Das bin ich. So war es, so ist es und so wird es immer sein. Meine Damen und Herren man riecht sich. Auf wiederhörnchen"
    },
  ]

  constructor(private titleService: Title, private ngZone: NgZone) {
    this.titleService.setTitle("IT Service | NBM");
  }

  ngOnInit(): void {
  }

  stopHero(): void {    
    if (Math.round(this.animationItem.currentFrame) === 97) {
      this.ngZone.runOutsideAngular(() => {
        this.animationItem.pause();
      });
    }
  }
  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    
  }


 

  onAppear() {
    this.animationCreated(this.animationItem)
    
  }

}