import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCaptchaModule } from 'ngx-captcha';
//Services

//Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { ExperienceComponent } from './pages/home/experience/experience.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContactComponent } from './shared/contact/contact.component';
import { AboutComponent } from './pages/home/about/about.component';
import { ImpressumComponent } from './pages/legal/impressum/impressum.component';
import { HomeComponent } from './pages/home/home.component';
import { MobilePageComponent } from './pages/experiences/mobile-page/mobile-page.component';
import { DataPageComponent } from './pages/experiences/data-page/data-page.component';
import { WebdevPageComponent } from './pages/experiences/webdev-page/webdev-page.component';
import { IndividualsoftwarePageComponent } from 'src/app/pages/experiences/individualsoftware-page/individualsoftware-page.component';
import { TechGridComponent } from './shared/tech-grid/tech-grid.component';
import { WrapperComponent } from './shared/wrapper/wrapper.component';
import { EntwicklungsprozesseComponent } from './pages/experiences/individualsoftware-page/components/entwicklungsprozesse/entwicklungsprozesse.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SpacerComponent } from './shared/spacer/spacer.component';
import { ItServicePageComponent } from './pages/experiences/it-service-page/it-service-page.component';
import { AccordionModule } from 'src/app/shared/accordion/accordion.module';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { MoreInfoButtonComponent } from './shared/more-info-button/more-info-button.component';
import { EntwicklungsprozesseMobileComponent } from './pages/experiences/individualsoftware-page/components/entwicklungsprozesse-mobile/entwicklungsprozesse-mobile.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { CookieService } from 'ngx-cookie-service';
import { UeberUnsComponent } from './pages/ueber-uns/ueber-uns.component';
import { HeroComponent } from './pages/home/hero/hero.component';
import { DemosComponent } from './pages/home/demos/demos.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ThreeComponent } from './pages/home/hero/three/three.component';
import { StartupComponent } from './pages/experiences/startup/startup.component';
import { ReferencesComponent } from './pages/home/references/references.component';
import { VisualComponent } from './pages/home/hero/visual/visual.component';
import { AnimationComponent } from './pages/experiences/animation/animation.component';
import { DatenschutzComponent } from './pages/legal/datenschutz/datenschutz.component';
import { ContactPersonComponent } from './shared/contact-person/contact-person.component';
import { IttComponent } from './pages/experiences/itt/itt.component';
import { DarkModeToggleComponent } from './shared/header/dark-mode-toggle/dark-mode-toggle.component';
import { MainExperienceComponent } from './pages/home/main-experience/main-experience.component';
import { ProcessOptimizationComponent } from './pages/experiences/process-optimization/process-optimization.component';
import { OtherExperiencesComponent } from './pages/other-experiences/other-experiences.component';

export function playerFactory() {
  return player;
}

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ExperienceComponent,
    FooterComponent,
    ContactComponent,
    AboutComponent,
    ImpressumComponent,
    HomeComponent,
    IndividualsoftwarePageComponent,
    MobilePageComponent,
    DataPageComponent,
    WebdevPageComponent,
    TechGridComponent,
    WrapperComponent,
    EntwicklungsprozesseComponent,
    SpacerComponent,
    ItServicePageComponent,
    ContactPageComponent,
    MoreInfoButtonComponent,
    EntwicklungsprozesseMobileComponent,
    UeberUnsComponent,
    HeroComponent,
    DemosComponent,
    ThreeComponent,
    StartupComponent,
    ReferencesComponent,
    VisualComponent,
    AnimationComponent,
    DatenschutzComponent,
    ContactPersonComponent,
    IttComponent,
    DarkModeToggleComponent,
    MainExperienceComponent,
    ProcessOptimizationComponent,
    OtherExperiencesComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    AccordionModule,
    DirectivesModule,
    NgxCaptchaModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de-DE',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    
    
  ],
  providers: [
    CookieService,
    TranslatePipe
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }