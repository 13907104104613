<div class="tech-grid">
    <div class="card" *ngFor="let tech of techstack">
        <a href="{{tech.url}}" target="_blank">
            <div class="imgBx">
                <img src="{{tech.logopath}}">
            </div>
            <div class="contentBx">
                <p>{{tech.name}}</p>
            </div>
        </a>
    </div>
</div>