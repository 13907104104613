<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>

                <h1 [innerHtml]="'startup.title'|translate"></h1>
                <p>{{'startup.description'|translate}}</p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>
                <ng-lottie [options]="idea"></ng-lottie>
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <div class="section">
        <app-wrapper>
            <h1>{{'startup.section_1.title'|translate}}</h1>
            <div class="grid50">
                <div>
                    <table>
                        <tr>
                            <td class="list-symbol">
                                <div class="symbol-wrapper"></div>

                            </td>
                            <td>
                                <h3>{{'startup.section_1.card_1.title'|translate}}</h3>
                                <p [innerHtml]="'startup.section_1.card_1.text'|translate">
                                </p>

                            </td>
                        </tr>
                        <br>
                        <tr>
                            <td class="list-symbol">
                                <div class="symbol-wrapper"></div>

                            </td>
                            <td>
                                <h3 [innerHtml]="'startup.section_1.card_2.title'|translate"></h3>
                                <p [innerHtml]="'startup.section_1.card_2.text'|translate"></p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="imgwrapper">
                    <ng-lottie [options]="standout"></ng-lottie>
                </div>
            </div>
            <br>
            <div class="grid50">
                <div class="item">
                    <h2 [innerHtml]="'startup.section_2.title'|translate"></h2>
                    <br>
                    <p [innerHtml]="'startup.section_2.text'|translate"></p>
                    <ul>
                        <li *ngFor="let k of 'startup.section_2.keypoints'|translate" [innerHtml]="k"></li>
                    </ul>
                </div>
                <div class="item">
                    <h2 [innerHtml]="'startup.section_3.title'|translate"></h2>
                    <br>
                    <p [innerHtml]="'startup.section_3.text'|translate"></p>
                    <ul>
                        <li *ngFor="let k of 'startup.section_3.keypoints'|translate" [innerHtml]="k"></li>
                    </ul>
                </div>
            </div>
            
        </app-wrapper>
    </div>
    

</div>