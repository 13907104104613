<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>
                <h1>{{'itt.title' | translate}}</h1>
                <p [innerHtml]="'itt.description' | translate"></p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>
                <ng-lottie [options]="hero"></ng-lottie>
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <div class="section">
        <app-wrapper>
            <h1>{{'itt.section_1.title' | translate}}</h1>
            <div class="what">
                <div class="img">
                    <img src="./assets/images/itt/itt.webp" alt="">
                </div>
                <div class="text">
                    <p [innerHtml]="'itt.section_1.description' | translate"></p>
                    <div class="grid">
                        <div *ngFor="let i of 'itt.section_1.features' | translate" class="item">
                            <h3 [innerHtml]="i.title"></h3>
                            <p [innerHtml]="i.text"></p>
                        </div>
                    </div>
                </div>
            </div>
        </app-wrapper>
    </div>
    <app-wrapper>
        <div class="button-wrapper">
            <img src="./assets/images/itt/3d-showroom.webp" alt="" class="showroom" routerLink="/showroom">
            <a class="btn" routerLink="/showroom" routerLinkActive="active" target="_blank">Start Experience</a>
        </div>
    </app-wrapper>
    <div class="section_2">
        <app-wrapper>
            <h1>{{'itt.section_2.title'| translate}}</h1>
            <div class="cap-grid">
                <div class="cap" *ngFor="let i of 'itt.section_2.capabilities' | translate; let j = index">
                    <div class="title" [innerHtml]="i"></div>
                    <div class="image-box">
                        <img *ngIf="j===0" src="./assets/images/itt/messe.webp" alt="">
                        <img *ngIf="j===1" src="./assets/images/itt/office.webp" alt="">
                        <img *ngIf="j===2" src="./assets/images/itt/showroom.webp" alt="">
                        <img *ngIf="j===3" src="./assets/images/itt/stores.webp" alt="">
                        <img *ngIf="j===4" src="./assets/images/itt/vertrieb.webp" alt="">
                        <img *ngIf="j===5" src="./assets/images/itt/meer.webp" alt="">
                    </div>
                </div>
            </div>
        </app-wrapper>
    </div>
    <div class="section_3">
        <app-wrapper>
            <h1>{{'itt.section_3.title' | translate}}</h1>
            <div class="example">
                <div class="text">
                    <h2>{{'itt.section_3.card_1.title' | translate}}</h2>
                    <br>
                    <p [innerHtml]="'itt.section_3.card_1.text' | translate"></p>
                </div>
                <div class="img">
                    <img src="./assets/images/itt/portfolio.webp" alt="">
                </div>
            </div>
            <div class="example middle">

                <div class="text">
                    <h2>{{'itt.section_3.card_2.title' | translate}}</h2><br>
                    <p [innerHtml]="'itt.section_3.card_2.text' | translate"></p><br>
                    <a class="btn" routerLink="/showroom" routerLinkActive="active" target="_blank">Browser Demo</a>
                </div>
                <div class="img">
                    <img src="./assets/images/itt/3d-showroom.webp" alt="" class="showroom" routerLink="/showroom">
                </div>
            </div>
            <div class="example">
                <div class="text">
                    <h2>{{'itt.section_3.card_3.title' | translate}}</h2><br>
                    <p [innerHtml]="'itt.section_3.card_3.text' | translate"></p>
                </div>
                <div class="img">
                    <img src="./assets/images/itt/configurator.webp" alt="">
                </div>
            </div>
        </app-wrapper>
    </div>
</div>