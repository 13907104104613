import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  checked: boolean;
  lang= "de-DE"

  constructor(private router: Router, private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  switchLang(){
    if(this.lang == "de-DE"){
      this.lang = "en-US"
    }else{
      this.lang = "de-DE"
    }
    this.translateService.use(this.lang)
    console.log(this.lang)
  }

  async navigateExperiences() {
    this.close();
    await this.router.navigate(['/']);
    //timeout fixed bug, dass bis zum Ende der Seite gescrollt wird.
    setTimeout(() => {
      const element = document.getElementById('leistungen');
      element.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });   
    }, 1);
  }
  close(){
    this.checked= false;
  }
}
