<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>
                <h1>{{'mobile.title' | translate}}</h1>
                <p>{{'mobile.description' | translate}}</p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>
                <ng-lottie [options]="your_app"></ng-lottie>
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <section class="section">
        <app-wrapper>
            <h1>{{'mobile.section_1.title' | translate}}</h1>
            <div class="flex section1">
                <div class="animation2">

                    <ng-lottie (appear)="startScreenSize()" [options]="screen_size"
                        (animationCreated)="screenSizeCreated($event)" (enterFrame)="stopScreenSize()">
                    </ng-lottie>
                </div>
                <div>
                    <p>{{'mobile.section_1.description' | translate}}</p>
                    <h2>{{'mobile.section_1.headline_1' | translate}}</h2>
                    <p>{{'mobile.section_1.text_1' | translate}}</p>
                    <h2>{{'mobile.section_1.headline_2' | translate}}</h2>
                    <p>{{'mobile.section_1.text_2' | translate}}</p>
                </div>
            </div>
        </app-wrapper>
    </section>

    <section class="section section-1">
        <app-wrapper>
            <h1>{{'mobile.section_2.title' | translate}}</h1>
            <div class="gird">
                <div class="item" *ngFor="let item of items">
                    <div class="image-wrapper">
                        <ng-lottie [options]="item.imagePath">
                        </ng-lottie>
                    </div>
                    <h2>{{item.headline | translate}}</h2>
                    <p>{{item.description | translate}}</p>
                </div>
            </div>
            <app-contact-person name="Manuel Wolf"></app-contact-person>
        </app-wrapper>
    </section>
    
</div>