import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['../experiences.scss','./startup.component.scss']
})
export class StartupComponent implements OnInit {

  idea: AnimationOptions = {
    path: '../../assets/lottie/hero/startup/data.json',
  };
  standout: AnimationOptions = {
    path: '../../assets/lottie/icons/standout/data.json'
  };


  constructor() { }

  ngOnInit(): void {
  }

}
