<div>
    <app-hero></app-hero>
    <!-- <app-references></app-references> -->
    <app-wrapper>
        <div id="about">
            <app-about></app-about>
        </div>
        <div id="main-experience">
            
        </div>
    </app-wrapper>
    <app-main-experience></app-main-experience>
    <app-wrapper>
        
    </app-wrapper>
    <!-- <app-demos></app-demos> -->
</div>