<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>
                <h1>{{'it_service.title'|translate}}</h1>
                <p>{{'it_service.description'|translate}}</p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>
                <ng-lottie (appear)="onAppear()" [options]="hero" (animationCreated)="animationCreated($event)"
                    (enterFrame)="stopHero()">
                </ng-lottie>
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <section class="section section-1">
        <app-wrapper>
            <h1 scroll-animation [fadeDirection]="'up'">{{'it_service.section_1.title'|translate}}</h1>
            <div class="grid">
                <div class="item" *ngFor="let item of items" scroll-animation [fadeDirection]="'up'">
                    <div class="image-wrapper">
                        <ng-lottie [options]="item.imagePath">
                        </ng-lottie>
                    </div>
                    <h2>{{item.headline|translate}}</h2>
                    <p [innerHTML]="item.description | translate"></p>
                </div>
            </div>
        </app-wrapper>
    </section>
    <app-contact-person name="Manuel Wolf"></app-contact-person>
    
</div>