import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BasicMetaTags } from './module/BasicMetaTags';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  public author = 'NBM';
  public title = 'NewBeeMountain';
  public description = 'Ihr Partner für IT Lösungen';
  public basicKeywords = ['NewBeeMountain']

  constructor(
    private _meta: Meta
  ) { }

  public setBasicMetaTags(content: BasicMetaTags): void {
    this._meta.addTag({ name: 'title', content: this.title })
    this._meta.addTag({ name: 'description', content: this.description })
    this._meta.addTag({ name: 'author', content: this.author})
    this._meta.addTag({ name: 'keywords', content: content.keywords.concat(this.basicKeywords).join(',') });
  }
}
