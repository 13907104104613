<footer class="footer-distributed">


	<div class="footer-left">

		<img src="../../assets/logo_dark.svg" alt="">

		<p class="footer-links">
			<a routerLink="" class="link-1">Home &nbsp;</a>

			<a routerLink="impressum">{{'ui.header.imprint' | translate}}&nbsp;</a>
			<a routerLink="ueber-uns">{{'ui.header.about' | translate}}</a>
		</p>

		<p class="footer-company-name">Newbeemountain GmbH © 2023</p>
	</div>

	<div class="footer-center">

		<p><span>

				<a href="mailto:info@newbeemountain.com">info@newbeemountain.com</a>
			</span>
			Grünwald, Deutschland
		</p>


	</div>

	<div class="footer-right">

		<div class="footer-company-about">
			<span>About Newbeemountain</span>
			<p [innerHtml]="'home.about' | translate"></p>
			
		</div>

		<div class="footer-icons">

			<a href="https://de.linkedin.com/company/kiongki?trk=public_profile_topcard-current-company" target="_blank">
				<i class="bi bi-linkedin"></i>
			</a>
		</div>

	</div>

</footer>