import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-info-button',
  templateUrl: './more-info-button.component.html',
  styleUrls: ['./more-info-button.component.scss']
})
export class MoreInfoButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
