<div class="hero">
    <div class="hero_text">
        <img src="./assets/logo_dark.svg" alt="" class="logo">

        <br><br>
        <p>{{'home.hero.slogan' | translate}} </p>
        <br><br><br>
        <div class="instruction" >
            <div class="more" (click)="scrollDown()">
                <b>
                    {{'home.hero.instruction' | translate}}&nbsp;<i class="bi bi-arrow-down"></i>
                </b>
            </div>
        </div>
        <br>
       
    </div>
    <app-visual></app-visual>
</div>