<div class="color">
    <app-spacer></app-spacer>
    <app-wrapper>
        <div class="flex hero">
            <div>
                <h1>{{'individual_software.title' | translate}}</h1>
                <p>{{'individual_software.description' | translate}}
                </p>
                <br>
                <app-more-info-button></app-more-info-button>
            </div>
            <div>
                <ng-lottie (appear)="onAppear()" [options]="your_app" (animationCreated)="animationCreated($event)">
                </ng-lottie>
            </div>
        </div>
    </app-wrapper>
</div>
<div id="content">
    <section class="section section-1">
        <app-wrapper>
            <h1 scroll-animation [fadeDirection]="'up'">{{'individual_software.section_1.title' | translate}}
            </h1>
            <p scroll-animation [fadeDirection]="'up'">
                {{'individual_software.section_1.text' | translate}}
            </p>
            <app-entwicklungsprozesse></app-entwicklungsprozesse>
        </app-wrapper>
    </section>
    <section class="section section-2">
        <app-wrapper>
            <h1 scroll-animation [fadeDirection]="'up'">{{'individual_software.section_2.title' | translate}}
            </h1>
            <div class="flex">
                <div class="flexitem" scroll-animation [fadeDirection]="'right'">
                    <p>
                        {{'individual_software.section_2.text_1' | translate}}
                    </p>
                    <br>
                    <p>{{'individual_software.section_2.text_2' | translate}}
                    </p>
                </div>
                <app-tech-grid class="flexitem" [techstack]="techstack" scroll-animation [fadeDirection]="'left'"
                    [delay]="150"></app-tech-grid>
            </div>
            <app-contact-person name="Manuel Wolf"></app-contact-person>
        </app-wrapper>
    </section>   
</div>