import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor(private titleService:Title) { 
    this.titleService.setTitle("Impressum | NBM");
  }

  ngOnInit(): void {
  }

}
