import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataPageComponent } from 'src/app/pages/experiences/data-page/data-page.component';
import { IndividualsoftwarePageComponent } from 'src/app/pages/experiences/individualsoftware-page/individualsoftware-page.component';
import { ItServicePageComponent } from 'src/app/pages/experiences/it-service-page/it-service-page.component';
import { MobilePageComponent } from 'src/app/pages/experiences/mobile-page/mobile-page.component';
import { WebdevPageComponent } from 'src/app/pages/experiences/webdev-page/webdev-page.component';
import { HomeComponent } from './pages/home/home.component';
import { ImpressumComponent } from './pages/legal/impressum/impressum.component';
import { ContactPageComponent } from 'src/app/pages/contact-page/contact-page.component';
import { UeberUnsComponent } from './pages/ueber-uns/ueber-uns.component';
import { AnimationComponent } from './pages/experiences/animation/animation.component';
import { StartupComponent } from './pages/experiences/startup/startup.component';
import { ThreeComponent } from './pages/home/hero/three/three.component';
import { DatenschutzComponent } from './pages/legal/datenschutz/datenschutz.component';
import { IttComponent } from './pages/experiences/itt/itt.component';
import { ProcessOptimizationComponent } from './pages/experiences/process-optimization/process-optimization.component';
import { OtherExperiencesComponent } from './pages/other-experiences/other-experiences.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'kontakt', component: ContactPageComponent },
  { path: 'ueber-uns', component: UeberUnsComponent },
  { path: 'individualsoftware', component: IndividualsoftwarePageComponent },
  { path: 'mobiledevelopment', component: MobilePageComponent },
  { path: 'webdevelopment', component: WebdevPageComponent },
  { path: 'dataanalytics', component: DataPageComponent },
  { path: 'it-service', component: ItServicePageComponent },
  { path: 'animation', component: AnimationComponent },
  { path: 'startup', component: StartupComponent },
  { path: 'showroom', component: ThreeComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'newbeevision', component: IttComponent },
  { path: 'prozessoptimierung', component: ProcessOptimizationComponent },
  { path: 'other-experiences', component: OtherExperiencesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
