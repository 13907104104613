import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }
  scrollDown(){
    setTimeout(() => {
      const element = document.getElementById('about');
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });   
    }, 1);
  }

}
