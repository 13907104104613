<div id="wrapper">
    <canvas #canvas id="canvas"></canvas>
    <div id="ui">
        <div class="instruction" *ngIf="state==='start'">            
            <i class="bi bi-play-circle button" (click)="toOrbit()"></i>
        </div>
        <div class="configurator" *ngIf="state==='configurator' || state === 'skills'">
            <i class="bi bi-skip-backward-circle" (click)="toOrbit()"></i>
        </div>        
    </div>
</div>