<h1>Experiences and Services.</h1>
<div class="grid">
    <!-- <a class="item" routerLink="prozessoptimierung">
        <div class="title">{{'process-optimization.title' | translate}}</div>
        <div class="image-box">
            <img src="assets/images/experience/itt-banner.webp" alt="">
        </div>
    </a> -->
    
    <a class="item" routerLink="prozessoptimierung">
        <div class="title-box title-po">{{'home.main_experiences.process_optimization.title' | translate}}
            <br>
            <p>{{'home.main_experiences.process_optimization.text' | translate}}</p>
        </div>
              

        <div class="image-box">
            <img src="assets/images/services/bpmn.webp" alt="">
        </div>
    </a>


    <a class="item" routerLink="newbeevision">
        <div class="title-box title-itt"><img src="assets/images/services/nbvision_logo_white.svg" alt="">
            <br>
            <p>{{'home.main_experiences.newbeevision.text' | translate}}</p>
        </div>
        <div class="image-box">
            <img src="assets/images/services/newbeevision.webp" alt="">
        </div>
    </a>
    <a class="item " routerLink="individualsoftware">
        <div class="title-box title-individual">{{'home.main_experiences.custom_software.title' | translate}}</div>
        <div class="image-box">
            <img src="assets/images/services/individual.webp" alt="">
        </div>
    </a>
    <a class="item " routerLink="other-experiences">
        <div class="title-box title-other">{{'home.main_experiences.more.title' | translate}}
            <p>{{'home.main_experiences.more.text' | translate}}</p>
        </div>
        <div class="image-box">
            <img src="assets/images/services/other.webp" alt="">
        </div>
    </a>
</div>