<h1 scroll-animation [fadeDirection]="'up'">{{'home.experience.title' | translate}}</h1>
<div class="wrapper">    
    <div class="grid">
        
        <a class="item" routerLink="/webdevelopment" scroll-animation [fadeDirection]="'right'" [delay]="100">
            <div class="information">
                <div class="title">
                    {{'home.experience.webdev.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.webdev.subtitle' | translate}}
                </div>
                <br>
                <ul>
                    <li *ngFor="let keyword of 'home.experience.webdev.keywords' | translate">{{keyword}}</li>
                </ul>

            </div>
            <div class="text web">
                <div class="title">
                    {{'home.experience.webdev.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.webdev.subtitle' | translate}}

                </div>
            </div>
            <div class="image-box">
                <img src="assets/images/experience/webdev.webp" alt="">
            </div>
        </a>
        <a class="item" routerLink="/it-service" scroll-animation [fadeDirection]="'right'">
            <div class="information">
                <div class="title">
                    {{'home.experience.itservice.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.itservice.subtitle' | translate}}
                </div>
                <br>
                <ul>
                    <li *ngFor="let keyword of 'home.experience.itservice.keywords' | translate">{{keyword}}</li>
                </ul>
            </div>
            <div class="text service">
                <div class="title">
                    {{'home.experience.itservice.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.itservice.subtitle' | translate}}
                </div>
            </div>
            <div class="image-box">
                <img src="assets/images/experience/service.webp" alt="">
            </div>
        </a>
        <a class="item" routerLink="/mobiledevelopment" scroll-animation [fadeDirection]="'right'" [delay]="200">
            <div class="information">
                <div class="title">
                    {{'home.experience.apps.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.apps.subtitle' | translate}}
                </div>
                <br>
                <ul>
                    <li *ngFor="let keyword of 'home.experience.apps.keywords' | translate">{{keyword}}</li>
                </ul>

            </div>
            <div class="text mobile">
                <div class="title">
                    {{'home.experience.apps.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.apps.subtitle' | translate}}
                </div>
            </div>
            <div class="image-box">
                <img src="assets/images/experience/mobile.webp" alt="">
            </div>
        </a>
        <!-- <a class="item" routerLink="/individualsoftware" scroll-animation [fadeDirection]="'right'">
            <div class="information">
                <div class="title">
                    {{'home.experience.individual_software.title' | translate}}
                </div>

                <div class="description">
                    {{'home.experience.individual_software.subtitle' | translate}}
                </div>
                <br>
                <ul>
                    <li *ngFor="let keyword of 'home.experience.individual_software.keywords' | translate">{{keyword}}
                    </li>
                </ul>
            </div>
            <div class="text individualsoftware">
                <div class="title">
                    {{'home.experience.individual_software.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.individual_software.subtitle' | translate}}
                </div>
            </div>
            <div class="image-box">
                <img src="assets/images/experience/backend.webp" alt="">
            </div>
        </a>
        <a class="item" routerLink="/dataanalytics" scroll-animation [fadeDirection]="'right'" [delay]="100">
            <div class="information">
                <div class="title">
                    {{'home.experience.business.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.business.subtitle' | translate}}
                </div>
                <br>
                <ul>
                    <li *ngFor="let keyword of 'home.experience.business.keywords' | translate">{{keyword}}</li>
                </ul>
            </div>
            <div class="text data">
                <div class="title">
                    {{'home.experience.business.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.business.subtitle' | translate}}
                </div>
            </div>
            <div class="image-box">
                <img src="assets/images/experience/data.webp" alt="">
            </div>
        </a> -->

        <a class="item" routerLink="/animation" scroll-animation [fadeDirection]="'right'" [delay]="100">
            <div class="information">
                <div class="title">
                    {{'home.experience.animation.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.animation.subtitle' | translate}}
                </div>
                <br>
                <ul>
                    <li *ngFor="let keyword of 'home.experience.animation.keywords' | translate">{{keyword}}
                    </li>
                </ul>
            </div>
            <div class="text animation">
                <div class="title">
                    {{'home.experience.animation.title' | translate}}
                </div>
                <div class="description">
                    {{'home.experience.animation.subtitle' | translate}}
                </div>
            </div>
            <div class="image-box">
                <img src="assets/images/experience/animation.webp" alt="">
            </div>
        </a>
        <br>
        <!-- <a class="item startup" routerLink="/startup" scroll-animation [fadeDirection]="'right'" [delay]="100">
            <div class="title">{{'home.experience.startup.title' | translate}}</div>
            <div class="image-box">
                <img src="assets/images/experience/startup.webp" alt="">
            </div>
        </a> -->

    </div>
</div>