import { Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { techStackItem } from 'src/app/models/techstack-item';

@Component({
  selector: 'app-data-page',
  templateUrl: './data-page.component.html',
  styleUrls: ['./data-page.component.scss', '../experiences.scss']
})
export class DataPageComponent implements OnInit {

  techstack: techStackItem[] = [
    {
      logopath: '../../assets/images/tech-logos/tableaulogo.webp',
      name: 'Tableau',
      url: 'https://www.tableau.com/de-de'
    },
    {
      logopath: '../../assets/images/tech-logos/powerbilogo.webp',
      name: 'Power BI',
      url: 'https://powerbi.microsoft.com/de-de/'
    },
    {
      logopath: '../../assets/images/tech-logos/mongodblogo.webp',
      name: 'Mongo DB',
      url: 'https://www.mongodb.com/de-de'
    },
    {
      logopath: '../../assets/images/tech-logos/mysqllogo.webp',
      name: 'MySQL',
      url: 'https://www.mysql.com/de/'
    },
    {
      logopath: '../../assets/images/tech-logos/mssqllogo.webp',
      name: 'MS SQL',
      url: 'https://www.microsoft.com/de-de/sql-server'
    },
    {
      logopath: '../../assets/images/tech-logos/firebaselogo.webp',
      name: 'Firebase',
      url: 'https://firebase.google.com/'
    },
    {
      logopath: '../../assets/images/tech-logos/pythonlogo.webp',
      name: 'Python',
      url: 'https://www.python.org/'
    },
    {
      logopath: '../../assets/images/tech-logos/excellogo.webp',
      name: 'Excel',
      url: 'https://www.microsoft.com/de-de/microsoft-365/excel'
    },
    {
      logopath: '../../assets/images/tech-logos/msprojectlogo.webp',
      name: 'MS Project',
      url: 'https://www.microsoft.com/de-de/microsoft-365/project/project-management-software?market=de'
    },
  ]

  your_app: AnimationOptions = {
    path: '../../assets/lottie/hero/data_analytics/data.json',
  };
  screen_size: AnimationOptions = {
    path: '../../assets/lottie/screen_size/data.json',
  };

  datenarchitekurItems = [
    {
      title: "Identifizierung",
      description: "Identifizierung von Daten, Datenströmen und Datenbeziehung."
    },
    {
      title: "Beschreibung",
      description: "Beschreibung von Daten, Datenströmen und Datenbeziehung."
    },
    {
      title: "Abhängigkeiten",
      description: "Finden von Abhängigkeiten der Daten, der Datenströme und der Datenbeziehungen untereinander und zwischen Unternehmensabteilungen."
    },
    {
      title: "Darstellung",
      description: "Übersichtliche und leicht veständliche Darstellung der Datenarchitektur des ganzen Unternehmens und der gewünschten Abteilung."
    },
  ]

  dateninfrastrukurItems = [
    {
      title: "Optimierung",
      description: "Wir optimieren bestehende Data Lakes, deren Auswertung und Visualisierung."
    },
    {
      title: "Visualisierung",
      description: "Wir visualisieren jegliche Erkenntnisse aus der Datenauswertung."
    },
    {
      title: "Auswertung",
      description: "Wir werten strukturierte Daten systematisch aus."
    },
    {
      title: "Strukturierung",
      description: "Wir strukturieren ihre Daten zur Weiterverarbeitung."
    },
    {
      title: "Data Lake",
      description: "Wir kümmern uns um die zweckmäßige Speicherung aller Rohdaten."
    },
  ]
  categoryItems = [
    {
      title: "Insights",
      description: "Nutzenstiftende Erkenntnis für spezifischen Einzelfall."
    },
    {
      title: "Harmonized Data",
      description: "Strukturierte Datenbasis mit theoretisch vielfältigen Nuetzungsoptionen."
    },
    {
      title: "Commodity",
      description: "Unstrukturierte Rohdaten mit unklarem Nutzungspotential, welche in Data Lakes (System, welches Rohdaten speichert) zusammengefasst werden."
    },
    
  ]

  private animationItem: AnimationItem;

  constructor(private titleService: Title, private ngZone: NgZone) {
    this.titleService.setTitle("Data Analytics | NBM");
  }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem.stop()
  }


  play(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.play();
    });
  }

  onAppear() {
    this.animationCreated(this.animationItem)
    this.animationItem.play()
  }

}

interface tech {
  logopath: string,
  name: string,
  url: string
}
