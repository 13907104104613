import { style, animate, AnimationBuilder } from '@angular/animations';
import {
  ElementRef, Output, Directive, AfterViewInit, OnDestroy, EventEmitter, Input, HostBinding
} from '@angular/core';
import { Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[scroll-animation]'
})
export class ScrollAnimationDirective implements AfterViewInit, OnDestroy {
  @Output() scroll: EventEmitter<void>;
  @Input() fadeDirection: string;
  @Input() duration: number = 450;
  @Input() delay: number = 0;

  @HostBinding('class')
  elementClass = '';
  @HostBinding('style.opacity')
  opacity: number = 0;

  elementPos: number;
  elementHeight: number;

  scrollPos: number;
  windowHeight: number;

  subscriptionScroll: Subscription;
  subscriptionResize: Subscription;

  constructor(private element: ElementRef, private builder: AnimationBuilder) {
    this.scroll = new EventEmitter<void>();
  }

  saveDimensions() {
    this.elementPos = this.getOffsetTop(this.element.nativeElement);
    this.elementHeight = this.element.nativeElement.offsetHeight;
    this.windowHeight = window.innerHeight;
  }
  saveScrollPos() {
    this.scrollPos = window.scrollY;
  }
  getOffsetTop(element: any) {
    let offsetTop = element.offsetTop || 0;
    if (element.offsetParent) {
      offsetTop += this.getOffsetTop(element.offsetParent);
    }
    return offsetTop;
  }
  checkVisibility() {
    if (this.isVisible()) {
      // double check dimensions (due to async loaded contents, e.g. images)
      this.saveDimensions();
      if (this.isVisible()) {
        this.startAnimation()
      }
    }
  }
  isVisible() {
    return this.scrollPos >= this.elementPos || (this.scrollPos + this.windowHeight) >= (this.elementPos + this.elementHeight / 2);
  }

  startAnimation() {
    let metadata
    switch (this.fadeDirection) {
      case 'up':
        metadata = this.fadeUp()
        break;
      case 'down':
        metadata = this.fadeDown()
        break;
      case 'right':
        metadata = this.faceRight()
        break;
      case 'left':
        metadata = this.fadeLeft()
        break;
    }
    const factory = this.builder.build(metadata);
    const player = factory.create(this.element.nativeElement);
    player.play();
    this.unsubscribe();
    this.scroll.emit();
  }

  subscribe() {
    this.subscriptionScroll = fromEvent(window, 'scroll')
      .subscribe(() => {
        this.saveDimensions();
        this.saveScrollPos();
        this.checkVisibility();
      });
    this.subscriptionResize = fromEvent(window, 'resize')
      .subscribe(() => {
        this.saveDimensions();
        this.checkVisibility();
      });
  }

  unsubscribe() {
    if (this.subscriptionScroll) {
      this.subscriptionScroll.unsubscribe();
    }
    if (this.subscriptionResize) {
      this.subscriptionResize.unsubscribe();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.saveDimensions();
      this.subscribe();
      this.saveScrollPos();
      this.checkVisibility();
    }, 10);
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

  private fadeUp() {
    return [
      style({ opacity: 0, transform: 'translateY(20px)' }),
      animate(`${this.duration}ms ${this.delay}ms`, style({ opacity: 1, transform: 'translateY(0)' }))
    ]
  }
  private fadeDown() {
    return [
      style({ opacity: 0, transform: 'translateY(-20px)' }),
      animate(`${this.duration}ms ${this.delay}ms`, style({ opacity: 1, transform: 'translateY(0)' }))
    ]
  }
  private faceRight() {
    return [
      style({ opacity: 0, transform: 'translateX(-20px)' }),
      animate(`${this.duration}ms ${this.delay}ms`, style({ opacity: 1, transform: 'translateX(0)' }))
    ]
  }
  private fadeLeft() {
    return [
      style({ opacity: 0, transform: 'translateX(20px)' }),
      animate(`${this.duration}ms ${this.delay}ms`, style({ opacity: 1, transform: 'translateX(0)' }))
    ]
  }
}