import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-process-optimization',
  templateUrl: './process-optimization.component.html',
  styleUrls: ['./process-optimization.component.scss', '../experiences.scss']
})
export class ProcessOptimizationComponent implements OnInit {

  requirements: AnimationOptions = {
    path: '../../assets/lottie/icons/requirements/data.json',
  };
  code: AnimationOptions = {
    path: '../../assets/lottie/icons/code/data.json',
  };
  installation: AnimationOptions = {
    path: '../../assets/lottie/icons/installation/data.json',
  };
  bug: AnimationOptions = {
    path: '../../assets/lottie/icons/bug/data.json',
  };
  animations = [
    this.bug,this.requirements, this.installation
  ]




  

  constructor() { }

  ngOnInit(): void {
  }



}
